import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import BannerImageRight from "../images/bannerimage-binary.webp";
import BannerImageLeft from "../images/bannerimage-grid.png";
import BannerImageBV from "../images/verify-banner.svg";
import BVWallet from "../images/verify-wallet.svg";
import FTMSense from "../images/ftm-intelligence.svg";
import FTMPrint from "../images/ftm-print.svg";
import FTMCommunity from "../images/flow-community.webp";
import FTMLaw from "../images/ftm-law.svg";
import FTMFin from "../images/ftm-finance.svg";
import FTMCoin from "../images/ftm-coin.svg";
import Shield from "../images/shield.svg";
import Ken from "../images/Ken.png";
import Cindy from "../images/Cindy.png";
import AIBanner from "../images/ai-banner.png";
import "./index.scss";
import { Link } from "react-router-dom";

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={BannerImageRight}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-right"
    />
    <img
      src={BannerImageLeft}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-left"
    />
    <img
      src={AIBanner}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-bv"
    />
    <div className="bilic-article-template__glass bilic-article-template__glass-one d-none d-md-flex"></div>
    <div className="bilic-article-template__glass bilic-article-template__glass-two d-none d-md-flex"></div>
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      <span>Chat with our </span> <br className="d-lg-flex"/> Agents
    </h5>
    {/* <p>
      Bilic Verify is our novel AML tool for wallet risk factor check. Banks,
      crypto companies, custodians, exchanges, OTC desks, BTMs, fintechs and
      regtechs users can utilize Bilic Verify as a digital shield.{" "}
    </p> */}
    {/* <div className="bilic-article-template__hero__links">
    <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>
      
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>

    </div>

    <div className="launchlist-widget" data-key-id="LfUKSs" data-height="180px"></div> */}

  </>
);

const BVarticle = () => (
  <ArticleTemplate
  articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
  templatename="bilic-article-template-bv"
  articletemplateherobgimgs={
    <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
  }
>
    <section hidden className="bilic-article-template__content__tagline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11">
            <header className="">
              <h4>
                {" "}
                Verify <span>Wallet Risk</span> Factor
              </h4>
              <p>
                Bilic Verify serves as a digital shield. This solution protects
                consumers from sending their digital assets to a fraudulent
                wallet address that cannot be reversed.
              </p>
            </header>
          </div>
        </div>
      </div>
    </section>

    <section hidden className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={BVWallet} alt="" />
                  <h6>Verify Wallet Risk</h6>
                  <p>
                    Bilic verify can be used as a tool to verify wallet risk factor.
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={Shield} alt="" />
                  <h6>Digital Shield</h6>
                  <p>
                    Bilic verify can be used by banks, crypto companies, fintechs as a digital shield
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={FTMPrint} alt="" />
                  <h6>Anti-Money Laundering</h6>
                  <p>
                    Bilic verify can be used to prevent, detect, and report money laundering activities
                  </p>
                  <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Ken, CISO
                </h3>
                <p style={{ maxWidth: "533px" }}>
                  This is a role play training scenario with Ken, the Chief Information Security Officer (CISO) of a prominent organization. The trainee will be playing the role of a cybersecurity solution provider, and Ken will be playing the role of the decision-maker. The trainee will pitch the latest in cybersecurity solutions to Ken's organization. Ken, with his vast experience and unique personality, will then respond to their pitch, likely with insightful questions and concerns. The trainee will be scored based on their ability to address Ken's queries and their solution's alignment with the organization's needs.
                </p>
                <a target="_blank" href="https://dd.bilic.io/base/interview/gwt0izwhxl?id=64ac8ce92b57f4ecfc279e9d" className="btn">
                  {" "}
                  Start
                </a>
              </div>
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={Ken} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section hidden className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__illustration" style={{ marginRight: "43px" }}>
                <img className="img-fluid" src={Cindy} alt="" />
              </div>
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Cindy, CFO
                </h3>
                <p style={{ maxWidth: "533px" }}>
                  This is a role play training scenario with Cindy, the Chief Financial Officer (CFO) of a leading multinational corporation. The trainee will be playing the role of a hacker, attempting to employ various social engineering tactics to deceive Cindy and gain unauthorized access to sensitive financial data. Cindy, with her vast experience and knowledge of cybersecurity best practices, will respond to the trainee's attempts, showcasing her ability to identify and thwart potential security threats. The trainee will be scored based on their creativity, adaptability, and the techniques they employ, while Cindy will demonstrate the importance of vigilance and awareness in the face of potential cyber threats.
                </p>
                <a target="_blank" href="https://dd.bilic.io/base/interview/wqi2aoirib?id=64ac8ce92b57f4ecfc279e9d" className="btn">
                  {" "}
                  Start
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section hidden className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4 style={{ maxWidth: "560px" }}>
                  Who needs the intelligence marketplace
                </h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMLaw} alt="" />
                  <h3>Law Enforcement and Investigation Companies</h3>
                  {/* <p>
                    Blockchain forensics
                    takes data from the blockchain to interpret the flow of
                    digital assets
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMFin} alt="" />
                  <h3>Financial Institutions and Compliance Officers</h3>
                  {/* <p>
                    On May 27, 2021, the Dubai Electronic Security Center issued
                    a press release stating that the new cryptocurrency- Dubai
                    Coin
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>Intelligence Generators</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMCoin} alt="" />
                  <h3>Crypto Business</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </ArticleTemplate>
);

export default BVarticle;
