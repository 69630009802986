import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Embed from "react-embed";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Annoncement from "../../components/announcement/announcement";
import {
  LandingHeaderLinks,
  LandingHeaderButton,
  ArticleHeaderMobileLinks
} from "../../components/header/templateHeader";
// import { MegaMenu } from "../../components/header/megaMenu";
import "./landing.scss";
// import BrowserImage from "./images/browser.svg";
import BloombergImage from "./images/bloomberg.png";
import YahoofinanceImage from "./images/yahoofinance.png";
import NasdaqImage from "./images/nasdaq.png";
import BenzingaImage from "./images/benzinga.png";
import MarketwatchImage from "./images/marketwatch.png";
import BlogImageOne from "./images/blog-1.jpg";
import BlogImageTwo from "./images/blog-2.jpg";
import BlogImageThree from "./images/blog-3.jpg";
import ArrowDiff from "./images/diff.svg";
import ArrowMarket from "./images/market-arrow.svg";
import WatchSvg from "./images/watch.svg";
import DataSvg from "./images/db.svg";
import InvestigateSvg from "./images/investigate.svg";
import AdvisorImageMOD from "./images/mod.svg";
// import AdvisorImageMass from "./images/Icarus.png";
// import AdvisorImageMdx from "./images/mdx.svg";
import AdvisorImageUN from "./images/un.svg";
// import AdvisorImageAirbus from "./images/airbus.svg";
import AnnouncementLogo from "./images/aws-logo.svg";
import AdvisorImageDept from "./images/Dept.png";
import AdvisorImageBarclay from "./images/barclay.svg";
import AdvisorImagePlexal from "./images/plexal.svg";
import { Link } from "react-router-dom";


const Landing = () => {
  const el = useRef();
  const tl = useRef();
  useEffect(() => {
    const selectAnimationTarget = gsap.utils.selector(el);
    tl.current = gsap
      .timeline()
      .from(selectAnimationTarget(".c-announcement__text"), {
        opacity: 0,
        y: -20,
        duration: 1,
      })
      .from(selectAnimationTarget(".c-announcement__logo"), {
        opacity: 0,
        y: 20,
        duration: 0.55,
      });
  }, []);

  const LandingAnnouncementContent = () => (
    <>
      <a
        href="https://medium.com/@Bilic_/bilic-selected-for-aws-startup-loft-accelerator-program-975a4cc98abb"
        target="_blank"
        rel="noopener noreferrer"
        ref={el}
      >
        <div className="c-announcement__text">
          <p>
            🎉🎉 <span>Bilic</span> has been accepted into the{" "}
            <span> Amazon AWS startup loft program </span>
            <span className="d-none d-md-inline-block"> </span> {" "}
            🎉🎉{" "}
          </p>
        </div>
        <div className="c-announcement__logo">
          <img
            src={AnnouncementLogo}
            alt=""
            className="d-none d-md-inline-block"
          />
          <span className="d-none d-md-inline-flex">
            Read More <i className="fas fa-chevron-circle-right"></i>
          </span>
        </div>
      </a>
    </>
  );

  return (
    <>
      <Annoncement styleModifier="c-announcement--landing">
        <LandingAnnouncementContent />
      </Annoncement>
      <main className="bilic-landing">
        {/* <div className="background-glow d-none d-lg-flex">
      <div className="background-glow__wrap">
        <div className="background-glow__one" />
        <div className="background-glow__two" />
        <div className="background-glow__three" />
      </div>
    </div> */}

        <Header
          navtButton={<LandingHeaderButton></LandingHeaderButton>}
          mobileLinks={<ArticleHeaderMobileLinks></ArticleHeaderMobileLinks>}
          styling=""
        >
          <LandingHeaderLinks />
        </Header>
        <div className="bilic-landing__banner">
          <div className="bilic-landing__banner__manifest">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="http://svgjs.dev/svgjs"
              height="100%"
              width="100%"
              viewBox="0 0 2000 2000"
              preserveAspectRatio="none"
              opacity="1"
              style={{ position: 'absolute' }}
              className="c-referral__noise"
            >
              <defs>
                <filter
                  id="nnnoise-filter"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                  filterUnits="objectBoundingBox"
                  primitiveUnits="userSpaceOnUse"
                  colorInterpolationFilters="linearRGB"
                >
                  <feTurbulence
                    type="fractalNoise"
                    // baseFrequency="0.101"
                    baseFrequency="0.1"
                    numOctaves="4"
                    seed="15"
                    stitchTiles="stitch"
                    x="0%"
                    y="0%"
                    width="100%"
                    height="100%"
                    result="turbulence"
                  />
                  <feSpecularLighting
                    surfaceScale="14"
                    specularConstant="1"
                    specularExponent="20"
                    lightingColor="#132409"
                    x="0%"
                    y="0%"
                    width="100%"
                    height="100%"
                    in="turbulence"
                    result="specularLighting"
                  >
                    <feDistantLight azimuth="3" elevation="101" />
                  </feSpecularLighting>
                </filter>
              </defs>
              <rect width="100%" height="100%" fill="transparent" />
              <rect
                width="100%"
                height="100%"
                fill="#132409"
                filter="url(#nnnoise-filter)"
              />
            </svg>
            <div className="container-xl">
              <div className="row">
                <div className="col-12">
                  <div className="bilic-landing__banner__manifest__text">
                    {/* <h2 className="animate__animated animate__fadeInUp animate__delay-100ms">
                  Blockchain <span> data </span>{' '}
                  <br className="d-none d-lg-flex" /> forensics{' '}
                </h2>                 */}

                    <h2 className="animate__animated animate__fadeInUp animate__delay-100ms">
                      {/* world-class <span> data driven </span>{" "} */}
                      {/* Intelligence <span> data </span>{" "}
                      <br className="d-none d-lg-flex" /> forensics{" "} */}

                      Investigate <span> & </span>{" "} Prevent
                      <br className="d-none d-lg-flex" /> Digital Asset Fraud{" "}

                    </h2>

                    <p className="animate__animated animate__fadeInUp animate__delay-900ms">
                      Bilic is a cyber security startup with a focus on digital asset forensics, fraud prevention and secured payment services{" "}
                      {/* Bilic is a blockchain forensics and data analytics platform offering products to help defence agencies, government agencies, intelligence agencies, and financial institutions investigate, detect and prevent insecurity on blockchain networks{" "} */}
                      {/* Over <strong style={{ fontWeight: "500" }}>$932M</strong>{" "}
                      in Ilict funds being tracked in our{" "}
                      <strong style={{ fontWeight: "500"}}>
                        {" "}
                        <a
                          style={{ fontWeight: "500" }}
                          target="_blank"
                          href="https://ftm.bilic.io/marketplace"
                          rel="noreferrer"
                        >
                          Intelligence Marketplace
                        </a>
                      </strong> */}
                    </p>
                    {/* <a href="" className="btn">Join Waitlist</a> */}
                    <div className="bilic-landing__banner__manifest__buttons d-flex">
                      {/* <a href="https://discord.gg/Pc6EJCMSWk" className="btn">
                    <i className="fa-brands fa-discord" />{' '}
                    <span>Join the Discord</span>{' '}
                  </a> */}

                      {/* <a
                        href="https://ftm.bilic.io/"
                        className="btn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>Try Our App</span>
                        <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      </a> */}

                      <Link className="btn" to="/contact">Request a Demo
                      <i className="fa-sharp fa-solid fa-arrow-right"></i>
                      </Link>


                      <button
                        className="btn bilic-landing__modal-btn d-flex"
                        data-bs-toggle="modal"
                        data-bs-target="#landingModal"
                      >
                        <i className="fa-solid fa-play"></i>
                        <span className="d-none d-lg-flex flex-column">
                          Play this video <span>to see why we built bilic</span>
                        </span>
                      </button>
                    </div>
                    <div className="bilic-landing__banner__manifest__advisors d-flex flex-column">
                      <h4>Backed by</h4>

                      <div className="bilic-landing__banner__manifest__advisors__slide">
                        <img src={AdvisorImageMOD} alt="" />
                         <img src={AdvisorImageDept} alt="" />
                        <img src={AdvisorImageBarclay} alt="" />
                        <img src={AdvisorImagePlexal} alt="" />
                        <img src={AdvisorImageUN} alt="" />
                        {/* <img src={AdvisorImageAirbus} alt="" /> */}
                        {/* <img src={AdvisorImageMass} alt="" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img src={BrowserImage} alt="" className="bilic-landing__banner-svg" /> */}
        </div>

        <section className="bilic-landing__callout">
          <div className="bilic-landing__callout__left">
            <div className="bilic-landing__callout__text">
              {/* <h2>
                Bilic is <span>transforming</span> how the world performs
                <span> investigations</span> on the <span>blockchain</span>
              </h2>               */}

              <h2>
                A simple yet <span>powerful</span> solution that provides you actionable <span>intelligence</span> on the digital assets.
              </h2>

              <p>
                {" "}
                Using our novel <i className="emphas">
                  FTM (Follow the Money)
                </i>{" "}
                dashboard,{" "}
                <i className="emphas">Intelligence Data Warehouse (IDW) </i>,
                and
                <i className="emphas"> Investigative product suite</i>
              </p>
            </div>
            <div
              className="bilic-landing__callout__illustration"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img src={ArrowDiff} alt="" />
            </div>
          </div>
          <div className="bilic-landing__callout__right-1 bilic-landing__callout__right">
            <div className="">
              <img src={WatchSvg} alt="" />
            </div>
            <div className="" data-aos="fade-up" data-aos-delay="105">
              <h2>Investigation (Follow the Money)</h2>
              <p>
                We built a proprietary tool FTM provided to our pool of
                investigators and analyst and in return they offer expertise and
                group intelligence to solve investigations.
              </p>
            </div>
          </div>
          <div className="bilic-landing__callout__right-2 bilic-landing__callout__right">
            <div className="">
              <img src={DataSvg} alt="" />
            </div>
            <div className="" data-aos="fade-up" data-aos-delay="155">
              <h2>Prevent Digital Asset Fraud</h2>
              <p>
              Banks, crypto companies, custodians, exchanges, OTC desks, BTMs, fintechs and regtechs users can utilize Bilic services as a digital shield against fraud.
              </p>
            </div>
          </div>
          <div className="bilic-landing__callout__right-3 bilic-landing__callout__right">
            <div className="">
              <img src={InvestigateSvg} alt="" />
            </div>
            <div className="">
              <h2>Training and Certification</h2>
              <p>
                Our experts deliver bespoke individual or team trainings on
                Virtual Asset Compliance (VIC) and Virtual Asset Investigator
                (VIA) certifications. Our trainings and certifications can be
                conducted on-site, remotely or internationally and modified to
                fit the clients needs.
              </p>
            </div>
          </div>
          <div className="bilic-landing__callout__right-4 bilic-landing__callout__right">
            <img src={ArrowMarket} alt="" />
          </div>
        </section>

        <section className="bilic-landing__number-wrap">
          <div className="container">
            <section className="bilic-landing__number">
              <div
                className="bilic-landing__number__item"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h4>
                  <span>$11.7</span> Billion
                </h4>
                <p>
                  The annual global spending on blockchain solutions by 2022.
                </p>
              </div>
              <div
                className="bilic-landing__number__item"
                data-aos="fade-up"
                data-aos-delay="105"
              >
                <h4>
                  <span>$513</span> Million
                </h4>
                <p>
                  Value of Crypto currency theft worldwide from 2016 to 2020
                </p>
              </div>
              <div
                className="bilic-landing__number__item"
                data-aos="fade-up"
                data-aos-delay="110"
              >
                <h4>
                  <span>$406</span> Million
                </h4>
                <p>
                  Value of cryptocurrency received by known ransomware
                  addresses.
                </p>
              </div>
              <div
                className="bilic-landing__number__item"
                data-aos="fade-up"
                data-aos-delay="120"
              >
                <h4>
                  <span>$5.6</span> Trillion
                </h4>
                <p>Global blockchain market by 2030</p>
              </div>
            </section>
          </div>
        </section>

        <section className="bilic-landing__media">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="bilic-landing__media__heading">
                  <h3>
                    Bilic in the <span>media</span>{" "}
                  </h3>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="bilic-landing__media__list">
                  <div
                    className="bilic-landing__media__list__item"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <a href="https://www.bloomberg.com/press-releases/2022-04-08/bilic-io-to-launch-blockchain-forensics-and-data-gathering-solution-to-support-intelligence-agencies">
                      <img src={BloombergImage} alt="" />
                      <h6>
                        Bloomberg <i className="fa-solid fa-chevron-right" />{" "}
                      </h6>
                    </a>
                  </div>
                  <div
                    className="bilic-landing__media__list__item"
                    data-aos="fade-up"
                    data-aos-delay="110"
                  >
                    <a href="https://www.nasdaq.com/press-release/bilic.io-to-launch-blockchain-forensics-and-data-gathering-solution-to-support">
                      <img src={NasdaqImage} alt="" />
                      <h6>
                        Nasdaq <i className="fa-solid fa-chevron-right" />{" "}
                      </h6>
                    </a>
                  </div>
                  <div
                    className="bilic-landing__media__list__item"
                    data-aos="fade-up"
                    data-aos-delay="115"
                  >
                    <a href="https://www.marketwatch.com/press-release/bilicio-to-launch-blockchain-forensics-and-data-gathering-solution-to-support-intelligence-agencies-2022-04-08?mod=search_headline">
                      <img src={MarketwatchImage} alt="" />
                      <h6>
                        Marketwatch <i className="fa-solid fa-chevron-right" />{" "}
                      </h6>
                    </a>
                  </div>
                  <div
                    className="bilic-landing__media__list__item"
                    data-aos="fade-up"
                    data-aos-delay="120"
                  >
                    <a href="https://www.benzinga.com/pressreleases/22/04/26548956/bilic-io-to-launch-blockchain-forensics-and-data-gathering-solution-to-support-intelligence-agencie">
                      <img src={BenzingaImage} alt="" />
                      <h6>
                        Benzinga <i className="fa-solid fa-chevron-right" />{" "}
                      </h6>
                    </a>
                  </div>
                  <div
                    className="bilic-landing__media__list__item"
                    data-aos="fade-up"
                    data-aos-delay="125"
                  >
                    <a href="https://finance.yahoo.com/news/bilic-io-launch-blockchain-forensics-163400186.html?guccounter=1">
                      <img src={YahoofinanceImage} alt="" />
                      <h6>
                        Yahoo <i className="fa-solid fa-chevron-right" />{" "}
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bilic-landing__article">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="bilic-landing__article__item">
                  <a href="https://medium.com/bilic-io/counter-terrorism-use-cases-for-cryptocurrency-tracking-ransomware-payments-through-crypto-e3d4fdb2a1fd">
                    <img src={BlogImageOne} alt="" />
                    <h6>9th April 2022</h6>
                    <h3>
                      Counter Terrorism Use Cases for Cryptocurrency: Tracking
                      Ransomware Payments Through Crypto
                    </h3>
                    <p>
                      The emergence of cryptocurrency onto the digital currency
                      scene...
                      <span>Read More</span>
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="bilic-landing__article__item">
                  <a href="https://medium.com/bilic-io/from-moscow-to-istanbul-on-the-blockchain-how-cryptocurrency-is-strengthening-bonds-between-russia-359728e0373d">
                    <img src={BlogImageTwo} alt="" />
                    <h6>9th April 2022</h6>
                    <h3>
                      From Moscow to Istanbul on the blockchain: How
                      cryptocurrency is strengthening bonds between Russia and
                      Turkey
                    </h3>
                    <p>
                      In January 2022, the Russian Central Bank proposed a
                      ban...
                      <span>Read More</span>
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="bilic-landing__article__item">
                  <a href="https://medium.com/bilic-io/sophisticated-money-laundering-instruments-on-the-blockchain-and-where-to-find-them-cb40c7243e53">
                    <img src={BlogImageThree} alt="" />
                    <h6>2nd April 2022</h6>
                    <h3>
                      Sophisticated Money Laundering Instruments on the
                      Blockchain and Where to Find Them
                    </h3>
                    <p>
                      A significant part of the global economy is immersed in
                      dirty practices ...
                      <span>Read More</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bilic-landing__cta">
          <div className="container">
            <div className="">
              <div className="row justify-content-center text-center">
                <div className="col">
                  <header className="bilic-landing__cta__header">
                    <h2>Join the community for updates and rewards</h2>
                    <a href="https://discord.gg/Pc6EJCMSWk" className="btn">
                      <i className="fa-brands fa-discord" />{" "}
                      <span> Join the Discord</span>{" "}
                    </a>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade bilic-landing__modal"
          id="landingModal"
          aria-labelledby="landingModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header d-md-none">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="bilic-landing__modal__video">
                  {/* <Embed url="https://www.youtube.com/embed/u_XBamD7L3A" /> */}
                  {/* <Embed url="https://youtu.be/3NqFKGA_nq4" /> */}
                  <Embed url="https://youtu.be/mO9-S74hS2o" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Landing;
