import React from "react";
import { Link } from "react-router-dom";
import "../legal.scss";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";

import {
  TeamsHeaderButton,
  TeamsHeaderLinks,
} from "../../../components/header/templateHeader";

const Terms = () => (
  <main className="c-legal">
    <Header
      navtButton={<TeamsHeaderButton></TeamsHeaderButton>}
      styling="bilic-global-header__inner"
    >
      <TeamsHeaderLinks />
    </Header>
    <div className="c-legal__banner">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-9">
            <div className="bilic-team__header__text text-center d-flex flex-column align-items-center">
              <h3>TERMS & CONDITIONS</h3>
              <h5>The following document outlines bilic's Terms of Service</h5>
              <h6>Last Updated: August 29th, 2022</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="c-legal__content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7">
            <div
              className="c-legal__manifest"
              style={{ margin: "4.8rem 0 2.0rem 0", opacity: ".75" }}
            >
              <p>
                This privacy notice for Bilic Limited with company number:
                14232181 ('Company', 'we', 'us', or 'our',), describes how and
                why we might collect, store, use, and/or share ('process') your
                information when you use our services ('Services'), such as when
                you:
              </p>

              <ul>
                <li>
                  Visit our website at <a href="https://bilic.io/">bilic.io</a>,
                  or any website of ours that links to this privacy notice
                </li>
                <li>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </li>
              </ul>

              <p>
                Questions or concerns? Reading this privacy notice will help you
                understand your privacy rights and choices. If you do not agree
                with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us
                at admin@bilic.io.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>What personal information do we process?</h2>
              <p>
                What personal information do we process? When you visit, use, or
                navigate our Services, we may process personal information
                depending on how you interact with Bilic Limited and the
                Services, the choices you make, and the products and features
                you use.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Do we process any sensitive personal information?</h2>
              <p>
                We may process sensitive personal information when necessary
                with your consent or as otherwise permitted by applicable law.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Do we receive any information from third parties?</h2>
              <p>We do not receive any information from third parties.</p>
            </div>

            <div className="c-legal__text">
              <h2>How do we process your information?</h2>
              <p>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>
                In what situations and with which parties do we share personal
                information?
              </h2>
              <p>
                We may share information in specific situations and with
                specific third parties.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>How do we keep your information safe?</h2>
              <p>
                We have organisational and technical processes and procedures in
                place to protect your personal information. However, no
                electronic transmission over the internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot
                promise or guarantee that hackers, cybercriminals, or other
                unauthorised third parties will not be able to defeat our
                security and improperly collect, access, steal, or modify your
                information.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>What are your rights?</h2>
              <p>
               Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>How do you exercise your rights?</h2>
              <p>
              The easiest way to exercise your rights is by filling out our data subject request form available here, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.Want to learn more about what Bilic Limited does with any information we collect?

              </p>
            </div>

            <div className="c-legal__text">
              <h2>Security</h2>
              <p>
                In the FREE version of the Service, Hosts and Users can use
                “Small meeting” mode (up to 4 participants). In “Small meeting”
                mode, communication between participants are primarily sent
                through peer-to-peer connections, where audio and video streams
                are sent directly between participants and do not pass through
                any of our servers. Video and audio transmitted in the Service
                is then sent directly between the participants in a room and is
                encrypted (DTLS-SRTP) with client-generated encryption keys. In
                cases where a user is behind a strict firewall or NAT, video and
                audio need to be relayed via a TURN server, but end-to-end
                encryption is still maintained.
              </p>
              <p>
                If you have upgraded a room to PRO, you can choose to use “Large
                meeting” mode (up to 12 participants). Calls using “Large
                meeting” mode will use a dedicated server infrastructure to
                allow more people in conversation, and better stability. Your
                stream will be sent through video router servers which transmits
                it to the other participants in the call, and also transmits
                their streams to you. Streams will always be encrypted
                (DTLS-SRTP) in transit, but will be decrypted and re-encrypted
                when passing through the video routers. We operate an
                infrastructure of video routers distributed across the world,
                and you will be automatically routed to the closest one. The
                video router servers and all of our infrastructure adhere to
                strict security measures, preventing any eavesdropping or
                interruption of the video/audio streams.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Providing your personal data to others</h2>
              <p>
                We may share information about you with third parties in some
                circumstances, including: (1) with your consent; (2) to a
                service provider or partner who meets our data protection
                standards; (3) with academic or non-profit researchers, with
                aggregation, anonymization; (4) when we have a good faith belief
                it is required by law, such as pursuant to a subpoena or other
                legal process; (5) to protect the vital interest of others, when
                we have reason to believe that doing so will prevent harm to
                someone or illegal activities. Our categories of service
                providers and partners are:
              </p>
              <ul>
                <li>Hosting/infrastructure/storage providers</li>
                <li>Payment processors</li>
                <li>Analysis tools providers</li>
                <li>Marketing and email providers</li>
                <li>Recruiting tools providers</li>
                <li>Internal communication tools providers</li>
              </ul>
            </div>

            <div className="c-legal__text">
              <h2>Business Transfers</h2>
              <p>
                We may disclose your personal data to any member of our group of
                companies (this means our subsidiaries, our ultimate holding
                company and all its subsidiaries) insofar as reasonably
                necessary for the purposes, and on the legal bases, set out in
                this policy.
              </p>
              <p>
                In the case where we are involved in a merger, acquisition,
                bankruptcy, reorganization or sale of assets such that your
                information would be transferred or become subject to a
                different privacy policy, we will notify you in advance and give
                you the option to delete your data before the transfer.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>International transfers of your personal data</h2>
              <p>
                In some circumstances your personal data may be transferred to
                countries outside the European Economic Area (EEA). You
                acknowledge that personal data that you submit for publication
                through our website or services may be available, via the
                internet, around the world. We cannot prevent the use (or
                misuse) of such personal data by others. For information about
                what types of content you are, see this{" "}
                <Link to="/terms">Terms of Service.</Link>
              </p>
              <p>
                We and our other group companies have offices and facilities in
                Norway, Sweden, United States. The hosting facilities for
                Account information stored by bilic are situated in Ireland. The
                hosting facilities for Usage information are situated in Ireland
                and the United States. Transfers to the United States will be
                protected by appropriate safeguards, namely the use of standard
                data protection clauses adopted or approved by the European
                Commission, a copy of which can be obtained from{" "}
                <a
                  rel="noreferrer"
                  href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contr"
                  target="_blank"
                >
                  acts-transfer-personal-data-third-countries_en.
                </a>
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Retaining and deleting personal data</h2>
              <p>
                Personal data that we process for any purpose or purposes shall
                not be kept for longer than is necessary for that purpose or
                those purposes. We will retain your personal data as follows:
              </p>
              <p>
                Transaction information will be retained for a minimum period of
                5 years following the date of the transaction, and for a maximum
                period of 10 years following the date of the transaction.
              </p>
              <p>
                In some cases it is not possible for us to specify in advance
                the periods for which your personal data will be retained. In
                such cases, we will determine the period of retention based on
                the following criteria:
              </p>
              <p>
                Account information will be retained until you decide to delete
                your account on bilic. Information about you used for Product &
                Marketing communication will be retained as long as you have
                given us consent to use this information.
              </p>
              <p>
                The period of retention of usage information will be determined
                based on the need for historical data to determine statistical
                validity and relevance for product decisions and technical
                monitoring
              </p>
              <p>
                Regardless of the provisions above, we may retain your personal
                data where such retention is necessary for compliance with a
                legal obligation to which we are subject, or in order to protect
                your vital interests or the vital interests of another natural
                person.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Changes to this policy</h2>
              <p>
                We can change these Terms at any time. If a change is material,
                we’ll let you know before it takes effect. By using bilic on or
                after that effective date, you agree to the new Terms. If you
                don’t agree to them, you should contact bilic at admin@bilic.io
                with the request to delete your account before they take effect,
                otherwise your use of the Service and Content will be subject to
                the new Terms.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Managing and deleting your personal information</h2>
              <p>
                If you have a bilic account, you can access, and modify your
                personal information, in Settings. To delete your account, you
                should contact bilic at{" "}
                <span className="bilic-mail">admin@bilic.io</span> . If you
                request to delete your account, your information and content
                will be unrecoverable after that time. We may withhold personal
                information that you request to the extent permitted by law.
              </p>
            </div>

            <div className="c-legal__text">
              <h2>Your rights</h2>
              <p>
                As an individual you are granted rights according to the
                applicable data protection law:
              </p>
              <ul>
                <li>The right to access to your personal data</li>
                <li>The right to rectification of your personal data</li>
                <li>
                  The right to object to and restriction of our processing of
                  your personal data
                </li>
                <li>The right to data portability.</li>
              </ul>
              <p>
                To exercise your rights or if you otherwise have any questions
                regarding our processing of your personal data, we encourage you
                to contact us as described below. However, we also notify you
                that you may raise a complaint to a data protection authority.
                For any questions about this privacy policy, please contact{" "}
                <span>admin@bilic.io</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </main>
);

export default Terms;
