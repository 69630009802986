import React from "react";
import "./team.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import SamuelImage from "./images/samuel.png";
import MefeImage from "./images/mefe.jpg";
import LucasImage from "./images/lucas.jpg";
import MerlinImage from "./images/merlin.jpg";
import IbrahimImage from "./images/ibrahim.jpg";
import BarryImage from "./images/barry.jpg";
import InramImage from "./images/inram.jpg";
import RayImage from "./images/ray.jpg";
import GinaImage from "./images/gina.jpeg";
import { TeamsHeaderButton, TeamsHeaderLinks } from "../../components/header/templateHeader";
import ContactSection from "../../templates/contactSection";

const Team = () => (
  <main className="bilic-team">
    <Header
      navtButton={<TeamsHeaderButton></TeamsHeaderButton>}
      styling="bilic-global-header__inner"
    >
      <TeamsHeaderLinks />
    </Header>
    <div className="bilic-team__header">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-9 justify-content-center">
            <div className="bilic-team__header__text text-center d-flex flex-column align-items-center">
              <h6>Bilic Team</h6>
              <h5>The Brilliant Minds Behind Bilic</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bilic-team__list">
      <div className="container">
        <div className="bilic-team__list-wrap">
          <div className="row">
            <div className="grid-list">
              <div className="bilic-team__list__header">
                <h5>Team</h5>                
              </div>
              <div className="grid-list-wrap">
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={SamuelImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Saminu Salisu CTO</h4>
                    <h6>Data Engineer / Digital Forensics</h6>
                    <p>
                    Saminu Salisu is a Certified Cryptocurrency Investigator, and a PhD candidate at TU Wien with a research focus in Data Analytics and masters degree in Electronic Security and Digital Forensics. 
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/evenmatrix/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>

                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={MerlinImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Merlin Spöttl</h4>
                    <h6>Data Scientist</h6>
                    <p>
                      Merlin is a tech enthusiast, data scientist and former
                      partner in an AI startup with over 8 years of experience.
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/takuya-a-025a9140/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>

                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={RayImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Raymond Xu</h4>
                    <h6>Legal Consel</h6>
                    <p>
                      Raymond has provided legal services to british and far
                      east clients across a mix of industries including minning,
                      pharmaceutical, financial institutions, securities,
                      mergers and aquisations, energy and enviroment
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/mikealnamdy/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>

                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={InramImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Imran Naqvi</h4>
                    <h6>Software Developer</h6>
                    <p>
                      Imran Naqvi is a javascript developer and blockchain
                      enthusiast with 7 years of web development experience.
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/mikealnamdy/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>

                {/* <div className="bilic-team__list__item">
                <picture className="bilic-team__list__item__image" alt="">
                  <source
                    media="(min-width:465px)"
                    srcset=""
                  />
                  <img src={MihnoImage} alt="Team-Mihno" />
                </picture>
                <div className="bilic-team__list__item__info">
                  <h4>Miho Fukao</h4>
                  <h6>Project Coordinator</h6>
                </div>
              </div> */}
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={LucasImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Lukas Meilhammer</h4>
                    <h6>Operations</h6>
                    <p>
                      Lukas is a serial entrepreneur and blockchain investor
                      since 2017. Lukas manages company operations, automation
                      and client communication.{" "}
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/usman-salis-ba37a1155/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={MefeImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Irhimefe Otuburun</h4>
                    <h6> Business Intelligence</h6>
                    <p>
                      Irhimefe otuburn is a business and integration solutions
                      architect with a focus in operations and business
                      strategy.{" "}
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/sulaiman-muhammad-a41467195/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={IbrahimImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Wali Ibrahim Usman</h4>
                    <h6>Legal Counsel</h6>
                    <p>
                      Ibrahim wali is a Capital Markets and taxation lawyer. He
                      advices in mature and growth markets covering a range of
                      security products, including debt and equity, high yield,
                      structured finance and derivatives, corporate trust among
                      others.
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/ezimoha-ruth-tochi-6a703b157/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={GinaImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Regina Chukwunyere</h4>
                    <h6>Outreach and Partnerships </h6>
                    <p>
                    Regina Chukwunyere is a legal tech entrepreneur with a Master’s degree in International Trade and Commercial Law from the University of Durham.
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/aylinkilic/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
                <div className="bilic-team__list__item">
                  <picture className="bilic-team__list__item__image" alt="">
                    {/* <source
                    media="(min-width:465px)"
                    srcset=""
                  /> */}
                    <img src={BarryImage} alt="" className="default" />
                  </picture>
                  <div className="bilic-team__list__item__info">
                    <h4>Barry Pene</h4>
                    <h6>Forensics and Cybersecurity </h6>
                    <p>
                      Barry is a stern blockchain research/copywriter with
                      technical affinity to forensics and cybersecurity
                    </p>
                    {/* <ul className="nav">
                    <li className="nav-item mr-2">
                      <a
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/in/aylinkilic/"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <ContactSection></ContactSection>
    <Footer />
  </main>
);

export default Team;
