import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import "./index.scss";
import BannerImageRight from "../images/bannerimage-binary.webp";
import BannerImageLeft from "../images/bannerimage-grid.png";
import BannerImageFTM from "../images/bannerimage-mag-alt.webp";
import FTMFlow from "../images/ftm-flow-alt.png";
import FTMTrans from "../images/ftm-flow-trans-alt.png";
import FTMNote from "../images/ftm-note.svg";
import FTMPrint from "../images/ftm-print.svg";
import FTMView from "../images/ftm-view.svg";
import FTMLaw from "../images/ftm-law.svg";
import FTMSense from "../images/ftm-intelligence.svg";
import FTMFin from "../images/ftm-finance.svg";
import { Link } from "react-router-dom";


const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={BannerImageRight}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-right"
    />
    <img
      src={BannerImageLeft}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-left"
    />

    <img
      src={BannerImageFTM}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-ftm"
    />
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      <span> Investigate </span> transactions, identify nefarious{" "}
      <span> wallets </span>
    </h5>
    <p>
      FTM dashboard shows the wallet transaction history including funds sent
      and received, In/Outbound transfers, currencies received, smart contract
      calls, etc.{" "}
    </p>
    <div className="bilic-article-template__hero__links">
      {/* <a className="btn bilic-article-template__hero__links__btn" 
          // target="_blank" href="https://ftm.bilic.io/follow-the-money" rel="noreferrer">
          target="_blank" href="/contact" rel="noreferrer">
        Request a Demo
      </a> */}

      <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>
      
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>

    </div>
  </>
);

const FTMArticle = () => (
  <ArticleTemplate
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-ftm"
  >
    <section className="bilic-article-template__content__tagline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11">
            <header className="">
              <h4>
                Start <span>investigations</span> by following the money easily
              </h4>
              <p>
               To initiate an investigation involving money laundering, malware, fraud, scams, hacks or ransomware cases, the FTM dashboard can be quickly taught and used to track the flow of funds and automatically create a case report.
              </p>
            </header>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={FTMView} alt="" />
                  <h6>First steps of crime reports</h6>
                  <p>
                    Filing a case and adding data points are needed to
                    Investigate User Activity Right Away and Visualize
                    Transactional Relationships Automatically
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={FTMPrint} alt="" />
                  <h6>Investigators click to aggregate</h6>
                  <p>
                    Our investigators or users aggregate data for the report
                    with clicks, pattern recognition requests, or other features
                    to follow the money even over several blockchains.
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={FTMNote} alt="" />
                  <h6>The Tool visualizes to report accordingly</h6>
                  <p>
                    Visualize the risk scores or other data about wallets and
                    visualize all relevant transactions
                  </p>
                  <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Advanced <br /> <span>Data Visualisation</span> Components
                </h3>
                <p>
                  The dashboard is hosting several types of views, to find the
                  wallets gathering the funds perpetrators received, often from
                  newly created wallets. The tool is aggregating all the data
                  needed, thereby exposing general practices of fraud for
                  pattern recognition improvements, data for warnings and more
                  attack angles.
                </p>
                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={FTMFlow} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section
      className="bilic-article-template__content__teaser bilic-article-template__content__teaser-righttext"
      style={{ paddingTop: "13.6rem" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11">
            <div
              className="bilic-article-template__content__teaser-wrap"
              style={{ gap: "5rem" }}
            >
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={FTMTrans} alt="" />
              </div>
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  <span> Transaction history </span> even way back in time
                </h3>
                <p>
                  Our FTM dashboard shows the wallet transaction history
                  including funds sent and received, In/Outbound transfers,
                  currencies received, smart contract calls, etc. and
                  highlighted activities involving labeled wallets. Certified
                  investigators or temporary users can trace funds to a KYC’d
                  wallet, link suspected cases, and label wallets.
                </p>
                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4>Who needs the FTM dashboard ?</h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMFin} alt="" />
                  <h3>Law Enforcement and Investigation Companies</h3>
                  
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMLaw} alt="" />
                  <h3>Financial Institutions and Complaince Officers</h3>
               
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>Intelligence Generators</h3>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      hidden
      className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Siloed data is causing inefficiencies and inaccurate decisions
                </h3>
                <p>
                  <span>There’s increasing pressure from regulators</span>
                  Regulators expect more robust processes to detect criminal
                  activity—which you can’t do by monitoring transactions in
                  isolation.
                </p>
                <p>
                  <span>There’s increasing pressure from regulators</span>
                  Regulators expect more robust processes to detect criminal
                  activity—which you can’t do by monitoring transactions in
                  isolation.
                </p>
                <p>
                  <span>There’s increasing pressure from regulators</span>
                  Regulators expect more robust processes to detect criminal
                  activity—which you can’t do by monitoring transactions in
                  isolation.
                </p>
              </div>
              <div className="bilic-article-template__content__teaser__illustration"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </ArticleTemplate>
);

export default FTMArticle;
