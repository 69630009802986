import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./header.scss";

function Header({ navtButton, children, styling, mobileLinks }) {
  return (
    <header className={`bilic-global-header ${styling}`}>
      <div className="container-xl">
        <div className="bilic-global-header__navigation">
          <nav className="d-flex flex-wrap align-items-center justify-content-between justify-content-md-start">
            <a
              href="/"
              className="bilic-global-header__navigation__logo d-flex align-items-center col-md-auto text-decoration-none"
            >
              bilic
            </a>

            <div className="d-none d-md-flex col-md-auto ps-5">{children}</div>

            <div className="bilic-global-header__navigation__socials d-none d-md-flex col-md justify-content-end align-items-center ms-lg-auto">
              {/* <ul className="nav d-none d-xl-flex">
                <li
                  className="nav-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                >
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://www.linkedin.com/company/bilic/?viewAsMember=true"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                >
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://medium.com/bilic-io"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-medium" />
                  </a>
                </li>

                <li
                  className="nav-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                >
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://twitter.com/spectralhash"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://discord.gg/Pc6EJCMSWk"
                    rel="noopener noreferrer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="ownshare"
                  >
                    <i className="fab fa-discord" />
                  </a>
                </li>
              </ul> */}
              {navtButton}
            </div>
            <div className="mobile-nav-button col-auto d-md-none">
              <button
                className="btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBothOptions"
                aria-controls="offcanvasWithBothOptions"
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div className="mobile-nav-menu">
        <div
          className="offcanvas offcanvas-top"
          data-bs-scroll="true"
          tabIndex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
              <a
                href="/"
                className="text-decoration-none"
              >
                bilic
              </a>
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ><i className="fa-sharp fa-solid fa-circle-xmark"></i></button>
          </div>
          <div className="offcanvas-body">
            {mobileLinks}
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  // children: PropTypes.node.isRequired,
  navtButton: PropTypes.node.isRequired,
};

export default Header;
