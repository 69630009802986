import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from 'tsparticles';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./styles.css";

const ParticlesConfig = {
    autoplay: true,
    fullScreen: {
        enable: true,
        zIndex: 1,
    },
    particles: {
        number: {
            value: 10,
            density: {
                enable: false,
                value_area: 800,
            },
        },
        color: {
            value: '#fff',
        },
        shape: {
            type: 'star',
            options: {
                sides: 5,
            },
        },
        opacity: {
            value: 0.8,
            random: false,
            anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
            },
        },
        size: {
            value: 4,
            random: false,
            anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
            },
        },
        rotate: {
            value: 0,
            random: true,
            direction: 'clockwise',
            animation: {
                enable: true,
                speed: 5,
                sync: false,
            },
        },
        line_linked: {
            enable: true,
            distance: 600,
            color: '#ffffff',
            opacity: 0.4,
            width: 2,
        },
        move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
            },
        },
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: ['grab'],
            },
            onclick: {
                enable: false,
                mode: 'bubble',
            },
            resize: true,
        },
        modes: {
            grab: {
                distance: 400,
                line_linked: {
                    opacity: 1,
                },
            },
            bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
            },
            repulse: {
                distance: 200,
            },
            push: {
                particles_nb: 4,
            },
            remove: {
                particles_nb: 2,
            },
        },
    },
    retina_detect: true,
    background: {
        color: '#111',
        image: '',
        position: '50% 50%',
        repeat: 'no-repeat',
        size: 'cover',
    },
};

const options = {
    name: "Polygon Mask",
    interactivity: {
        events: {
            onClick: {
                enable: false,
                mode: "push"
            },
            onDiv: {
                elementId: "repulse-div",
                enable: false,
                mode: "repulse"
            },
            onHover: {
                enable: true,
                mode: "bubble",
                parallax: {
                    enable: false,
                    force: 2,
                    smooth: 10
                }
            }
        },
        modes: {
            bubble: {
                distance: 40,
                duration: 2,
                opacity: 8,
                size: 6
            },
            connect: {
                distance: 80,
                links: {
                    opacity: 0.5
                },
                radius: 60
            },
            grab: {
                distance: 400,
                links: {
                    opacity: 1
                }
            },
            push: {
                quantity: 4
            },
            remove: {
                quantity: 2
            },
            repulse: {
                distance: 200,
                duration: 0.4
            },
            slow: {
                active: false,
                radius: 0,
                factor: 1
            }
        }
    },
    particles: {
        color: {
            value: "#1a8754"
        },
        links: {
            blink: false,
            color: "#ffffff",
            consent: false,
            distance: 30,
            enable: true,
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            outModes: "bounce",
            speed: 1
        },
        number: {
            limit: 0,
            value: 200
        },
        opacity: {
            animation: {
                enable: true,
                speed: 2,
                sync: false
            },
            value: {
                min: 0.05,
                max: 0.4
            }
        },
        shape: {
            type: "circle"
        },
        size: {
            value: 1
        }
    },
    polygon: {
        draw: {
            enable: true,
            lineColor: "rgba(255,255,255,0.2)",
            lineWidth: 1
        },
        enable: true,
        move: {
            radius: 10
        },
        inline: {
            arrangement: "equidistant"
        },
        scale: 1,
        type: "inline",
        url: "https://particles.js.org/images/smalldeer.svg"
    },
    background: {
        color: "#00807f",
        image: "",
        position: "50% 50%",
        repeat: "no-repeat",
        size: "cover"
    }
};

const ACarticle = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });

    const [agentType, setAgentType] = useState('');  // State for the selected agent type

    const handleAgentTypeChange = (e) => {
        setAgentType(e.target.value);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleButtonClick = async (e) => {
        e.preventDefault();  // Prevents the default form submission behavior

        const { phone, name } = formData;
        const agentNumber = agentType;  // Assuming agentType is a valid agent number

        if (!phone || !agentNumber || !name) {
            alert('Please enter your name, phone number and select an agent');
            return;
        }

        try {
            const url = `https://ddagent-backend-production.up.railway.app/twilio/make-call/${phone}/${name || ''}/${agentNumber}`;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`Network response was not ok ${response.statusText}`);
            }

            const data = await response.json();
            console.log(data);  // Log the response data for debugging purposes
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };


    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);
    return (
        <div id='particle-background'>

            <div className="container">

                <header className="text-center text-white position-relative w-100" style={{ top: '10%', zIndex: 2 }}> {/* Updated header */}
                    <h1 className="mb-4 ">Speak to your AI coworkers of the future</h1>
                </header>

                <Particles
                    id='tsparticles'
                    particlesLoaded='particlesLoaded'
                    init={particlesInit}
                    loaded={particlesLoaded}
                    // options={options_4}
                    options={options}
                    height='100vh'
                    width='100vw'
                ></Particles>

                <div className="form-container form-container position-absolute top-50 start-50 translate-middle">

                    {/* <h1 className="mb-4">Meet your AI coworkers of the future</h1> */}

                    <div className="row">
                        {[
                            {
                                name: 'Chinyere',
                                profile: 'Business banking support staff, can help with document verification, reminders.',
                                image: 'https://i.pinimg.com/originals/b7/49/47/b74947543e3a30e0b6b2522033d0562b.jpg',
                                value: '6530128848a002f428db4724'
                            },
                            {
                                name: 'Mefe',
                                profile: 'He is an African Fintech Fraud expert, he can advise with issues such as charge backs, remittance, mobile wallet and fx transfers',
                                image: 'https://i.pinimg.com/736x/f3/78/4a/f3784adc704a1bc9bcb2e494cd39caee.jpg',
                                value: '6530183a48a002f428db4734'
                            },
                            {
                                name: 'Caitlin',
                                profile: 'She is a compliance expert with 8 years experince in cybersurity, and can advise on anything digital asset.',
                                image: 'https://www.sinologie-goettingen.de/wp-content/uploads/2020/12/Jeong-Profile-Pic-768x1024.jpg',
                                value: '6530195d48a002f428db4737'
                            },
                            {
                                name: 'Saminu',
                                profile: 'He is a Digital Forensics and fintech Investigator with experince in crypto related investigation and asset tracking and tracing.',
                                image: 'https://i.pinimg.com/736x/73/e4/31/73e431c783bf6eed4e1f324def98df66.jpg',
                                value: '65301a7a48a002f428db473c'
                            },
                        ].map((agent, index) => (
                            <div className="col-sm-3" key={index}>
                                <label className="card-label">  {/* Label element added here */}

                                    <div className="card">
                                        <img src={agent.image} className="card-img-top" alt={`${agent.name}'s image`} />
                                        <div className="card-body">
                                            <h5 className="card-title">{agent.name}</h5>
                                            <p className="card-text">{agent.profile}</p>
                                            <div className="form-check">
                                                <input
                                                    type="radio"
                                                    name="agent"
                                                    value={agent.value}
                                                    onChange={handleAgentTypeChange}
                                                    className="form-check-input"
                                                    id={`agent-${index}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>

                    <form className="text-center p-5 border rounded">

                        <>
                            {/* <select
                            className="form-select mb-4"  // Bootstrap class for styling
                            value={agentType}
                            onChange={handleAgentTypeChange}
                        >
                            <option value="" disabled>Select an agent you want to hear from</option>
                            <option value="650306d9271396d5ed2a5d49">EMI Agent</option>
                            <option value="support">Support Agent</option>
                            <option value="other">Other</option>
                        </select> */}
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                className="form-control mb-3"
                                pattern="^[a-zA-Z\s]{2,30}$"  // Regular expression to enforce the format
                                title="Please enter a name containing only letters and spaces, between 2 to 30 characters long"  // Tooltip text to explain the format

                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Company Email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control mb-3"
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Enter the Country code and phone number in format 447, not +447"
                                value={formData.phone}
                                onChange={handleChange}
                                className="form-control mb-3"
                                pattern="^[4][4][7]\d{9}$"  // Regular expression to enforce the format
                                title="Please enter a phone number in the format 447415197423"  // Tooltip text to explain the format

                            />
                            <button
                                onClick={handleButtonClick}
                                className="btn btn-success"
                            >
                                Call me in 10 seconds
                            </button>
                        </>

                        {/* <footer className="mt-4 text-white">
                            <p>or call +447488881118</p>
                        </footer> */}

                    </form>


                </div>

            </div>
        </div>

    );
};

export default ACarticle;
