import React from "react";
import { Link } from "react-router-dom";
import "./article-template.scss";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import ContactSection from "../contactSection";
import BannerHeadCurve from "./images/banner-curve-alt.svg";
import { ArticleHeaderLinks } from "../../components/header/templateHeader";
import { ArticleHeaderMobileLinks } from "../../components/header/templateHeader";
import { PopupWidget } from 'react-calendly';

// const ArticleTemplateHeaderLinks = () => (
//   <ul className="nav bilic-global-header__navigation__nav justify-content-lg-start">
//     <li className="bilic-global-header__navigation__link">
//       <Link to="/">Home</Link>
//     </li>
//     <li className="bilic-global-header__navigation__link">
//       <Link to="/team">Team</Link>
//     </li>
//     {/* <li className="bilic-global-header__navigation__link">
//       <a href="/">Research</a>
//     </li>
//     <li className="bilic-global-header__navigation__link">
//       <a href="/">Training</a>
//     </li> */}
//   </ul>
// );



const ArticleTemplateHeaderButton = () => (
  <div className="bilic-global-header__navigation__btn">
    {/* <Link to="/contact" className="btn main">Book a free 30min Demo</Link> */}

    <PopupWidget
      url="https://calendly.com/saminated/60-minutes"
      rootElement={document.getElementById('root')}
      text="Book a free 30min Demo"
      textColor="#50dd29"
      color="#50dd29"
      // className="btn main"
    />

  </div>
);

const ArticleTemplate = ({
  children,
  articletemplateherobgimgs,
  articletemplatehero,
  articletemplateheroposition,
  articletemplateheroillustration,
  templatename
}) => (
  <main className={`bilic-article-template ${templatename}`}>
    <Header
      navtButton={<ArticleTemplateHeaderButton></ArticleTemplateHeaderButton>}
      mobileLinks={<ArticleHeaderMobileLinks></ArticleHeaderMobileLinks>}
      styling="bilic-global-header__inner"
    >
      <ArticleHeaderLinks></ArticleHeaderLinks>
    </Header>
    <section className="bilic-article-template__hero">
      {articletemplateherobgimgs}
      <div className="container-xl">
        <>
          <div className={`row justify-content-${articletemplateheroposition}`}>
            <>
              {articletemplatehero}
            </>

          </div>
          {/* <div className="row">
            <div className="col">
              <div className="bilic-article-template__hero__text d-flex flex-column">
                <>
                  {articletemplatehero}
                </>
              </div>
            </div>
          </div> */}
        </>
      </div>
      <div className="container-xl">
        <>
          <div className="row justify-content-center">
            <>
              {articletemplateheroillustration}
            </>
          </div>
        </>

      </div>
      <img src={BannerHeadCurve} alt="" className="bilic-article-template__curve d-none d-lg-flex" />
    </section>

    <section className="bilic-article-template__content">
      <div className="bilic-article-template__content-wrap">
        {children}

        <ContactSection></ContactSection>

      </div>
    </section>
    <Footer />
  </main>
);

export default ArticleTemplate;
