import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Lenis from '@studio-freight/lenis'
import SplitType from 'split-type';
import { PopupWidget } from 'react-calendly';
import { animate, stagger, inView } from 'motion'
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import Annoncement from "../../../components/announcement/announcement";
import NeoBannerImage from "../images/sparkles.png";
import NeoBannerApp from "../images/Prompt.webp";
// import BannerSwirl from "../images/banner-swirl.svg";
// import IDWTransaction from "../images/transaction.svg";
// import IDWLocation from "../images/location.svg";
// import IDWSocial from "../images/social.svg";
// import FTMCommunity from "../images/flow-community.webp";announce-intel
import FTMSource from "../images/flow-source.webp";
import FTMSense from "../images/ftm-intelligence.svg";
import NeoTransactionCard from "../images/risk-card.svg";
import NeoSearchTrans from "../images/search-transaction.svg";
import NeoBars from "../images/bars.svg";
import NeoUser from "../images/user.svg";
import NeoStacks from "../images/trans-chips.svg";
import NeoCaps from "../images/trans-caps.svg";
import NeoWalletIcon from "../images/wallet.svg";
import NeoSecuritytIcon from "../images/security.svg";
import NeoDataIcon from "../images/datacard.svg";
import NeoCheckIcon from "../images/check.svg";
import RouteCardSVG from "../images/route-card.svg";
import MetricsCardSVG from "../images/metric-card.svg";
import AdvisorImageAmazon from "../images/aws-logo.svg";
import AdvisorImageBarclay from "../images/barclay.svg";
import AdvisorImagePlexal from "../images/plexal.svg";
import AdvisorImageCyber from "../images/cyber-runway.svg";
import MatrixLineOne from "../images/matrix-1.svg";
import MatrixLineTwo from "../images/matrix-2.svg"
import MatrixLineThree from "../images/matrix-3.svg"
// import Announcement from "../images/aws-logo-alt.svg";
import Announcement from "../images/announce-intel.svg";
import "./index.scss";

const LandingAnnouncementContent = () => {

  const el = useRef();
  const tl = useRef();
  useEffect(() => {
    const selectAnimationTarget = gsap.utils.selector(el);
    tl.current = gsap
      .timeline()
      .from(selectAnimationTarget(".c-announcement__text"), {
        opacity: 0,
        y: -20,
        duration: 1,
      })
      .from(selectAnimationTarget(".c-announcement__logo"), {
        opacity: 0,
        y: 20,
        duration: 0.55,
      });
  }, []);



  return(

  <>
    <a
        href="https://www.linkedin.com/posts/saminu-salisu_hackathon-frauddetection-bilic-activity-7115253885136478208-5Wfx/?utm_source=share&utm_medium=member_desktop"
      target="_blank"
      rel="noopener noreferrer"
      ref={el}
    >
      <div className="c-announcement__text">
        <p>
          🎉🎉 <span>Bilic</span> won the intel AI hackathon {" "}
            {/* <span> Intel Corporation Liftoff program </span> */}
          <span className="d-none d-md-inline-block"> </span> {" "}
          🎉🎉{" "}
        </p>
      </div>
      <div className="c-announcement__logo">
        <img
            src={Announcement}
          alt=""
          className="d-none d-md-inline-block"
        />
        <span className="d-none d-md-inline-flex">
          Read More <i className="fas fa-chevron-circle-right"></i>
        </span>
      </div>
    </a>
  </>
)};

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={NeoBannerImage}
      alt=""
      className="bilic-article-template__hero__background"
    />
  </>
);

const ArticleTemplateHero = () => {
  const el = useRef();
  const q = gsap.utils.selector(el);

  useEffect(() => {
    el.current = gsap
      .timeline()
      .from(q('.hidetextref'), {
        y: '150%',
        ease: 'power4.out',
        duration: 0.5,
        delay: 0.4,
        stagger: 0.6,
      })
      .from(q('.line-border-one'), {
        scaleX: 0,
        duration: 0.3,
        transformOrigin: "left center",
        ease: "power2.inOut",
        stagger: 0.4,
      })
      .from(q('.line-border-three'), {
        scaleX: 0,
        duration: 0.3,
        transformOrigin: "left center",
        ease: "power2.inOut",
        stagger: 0.2,
      })
      .from(q('.line-border-two'), {
        scaleY: 0,
        duration: 0.3,
        transformOrigin: "left top",
        ease: "power2.inOut",
        stagger: 0.2,
      })
      .from(q('.line-border-four'), {
        scaleY: 0,
        duration: 0.3,
        transformOrigin: "left bottom",
        ease: "power2.inOut",
        stagger: 0.2,
      });

    const heading = new SplitType('#heading', { types: 'words' })
    const para = new SplitType('#para', { types: 'lines' });
    const heroElements = [...heading.words, ...para.lines];

    animate(heroElements, { y: [28, 0], opacity: [0, 1] }, { duration: 0.8, delay: stagger(0.6) });

    animate('.bilic-global-header', { y: [-28, 0], opacity: [0, 1] }, { duration: 1, delay: stagger(0.5) });

  }, [q]);

  return (
    <>
      <div className="col col-xxl-10">
        <div className="bilic-article-template__hero__text d-flex flex-column">
          <div className="w-100 d-flex flex-column align-items-center" ref={el}>
            {/* <small>💥  Introducing risk assesment agent </small> */}
            <h1 className="heading heading--one">
              <div className="line-border">
                <div className="line-border line-border-one"></div>
                <div className="line-border line-border-two"></div>
                <div className="line-border line-border-three"></div>
                <div className="line-border line-border-four"></div>
              </div>
              <span className="hidetextref"><span>AI-Driven agents</span></span>
            </h1>
            <h1 className="heading heading--two">
              <span className="hidetextref"><span>for finance security <span className="d-none d-md-inline-flex">and</span></span></span>
            </h1>
            <h1 className="heading heading--two">
              <span className="hidetextref"><span><small><span className="d-inline-flex d-md-none">and </span> compliance</small></span></span>
            </h1>
            <p id="para">
              Our AI-driven agents are designed to <br className="d-inline-block d-md-none" /> enhance traditional finance <br className="d-none d-lg-inline-block" />
              compliance by <br className="d-inline-block d-md-none" /> providing advanced capabilities in tracking <br className="d-inline-block d-md-none" /> analyzing<br className="d-none d-lg-inline-block" />
              and investigating financial activities.<br className="d-none d-lg-inline-block" />
            </p>
            <div className="bilic-article-template__hero__links">

              <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Speak to Sales</Link>
              {/* <PopupWidget
                url="https://calendly.com/saminated/60-minutes"
                rootElement={document.getElementById('root')}
                text="Get in touch"
                textColor="#ffffff"
                color="#319795"
              /> */}

              <a
                className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt animateanchor"
                // target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
                // target="_blank" href="https://prod-hosted-rephraseai.s3-accelerate.amazonaws.com/videos/23aa18dd-e0ec-4200-856e-c2795926b1f7.mp4" rel="noreferrer">
                target="_blank" href="https://www.loom.com/share/627aef2a3119448d80765d8588f3195c" rel="noreferrer">
                Watch a Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const ArticleTemplateHeroIllustration = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    // const heroImage = document.querySelector(".bilic-article-template__hero__image");
    // const scrollTarget = document.querySelector("bilic-article-template__content__solutions");

    animate(".bilic-article-template__hero__image", { transform: 'perspective(1200px) translateX(0px) translateY(-100px) scale(1) rotate(0deg) rotateX(32deg) rotateY(0deg) translateZ(0px)', opacity: [0, 1] }, { duration: 0.8, delay: 0.5 });

    gsap.to(".bilic-article-template__hero__image", {
      transform: 'translateY(0px) rotateX(0deg)',
      perspective: 'none',
      duration: 2,//
      ease: "power2.inOut",
      scrollTrigger: {
        trigger: '.animateanchor',
        start: '00',
      },
    });


  }, []);

  return (
    <div className="col col-md-10">
      <div className="bilic-article-template__hero__image">
        <>
          <img
            src={NeoBannerApp}
            alt=""
            className=""
          />
        </>
      </div>
    </div>
  )
};

const NEOArticle = () => {
  gsap.registerPlugin(ScrollTrigger);
  const tl = useRef();
  const t = gsap.utils.selector(tl);
  // const triggerStart = document.querySelector('.triggerstart');
  // const animation = gsap.timeline();
  useEffect(() => {
    const stacks = document.querySelectorAll('.stack');
    const spacer = 20;
    const minScale = 0.8;
    const distributor = gsap.utils.distribute({ base: minScale, amount: 0.2 });
    const heading = new SplitType('.bilic-article-template__content__tagline__heading', { types: 'lines' });


    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: '.bilic-landing__banner__manifest__advisors__slide',
          start: `top center+=100px`,
          end: '1500',
          // markers: true,
        }
      })
      .from(t('.bilic-article-template__content__heading h4'), {
        opacity: 0,
        y: -40,
        duration: 0.5,
        ease: "power4.out",
        stagger: 0.2,
      })
      .from(t('.bilic-article-template__content__heading p'), {
        opacity: 0,
        y: 20,
        duration: 0.6,
        ease: "power4.out",
        stagger: 0.4,
      })
      .from(t('.wraplast'), {
        opacity: 0,
        y: 20,
        duration: 0.7,
        ease: "power4.out",
        stagger: 0.2,
      })




    heading.lines.forEach((target) => {
      gsap.to(target, {
        backgroundPositionX: 0,
        ease: "none",
        scrollTrigger: {
          trigger: target,
          scrub: 1,
          start: "top center",
          end: "bottom center"
        }
      });
    });


    inView(".bilic-article-template__content__howto", ({ target }) => {
      animate(
        target.querySelector(".bilic-article-template__content__howto__header"),
        { opacity: 1, transform: "none" },
        { delay: 0.8, duration: 1, easing: [0.17, 0.55, 0.55, 1] }
      );
    });




    stacks.forEach((stack, index) => {

      const scaleVal = distributor(index, stacks[index], stacks);

      const tween = gsap.to(stack, {
        scrollTrigger: {
          trigger: stack,
          start: `top top`,
          scrub: true,
          // markers: true,
          invalidateOnRefresh: true
        },
        ease: "none",
        scale: scaleVal
      });

      ScrollTrigger.create({
        trigger: stack,
        start: `top-=${index * spacer} top`,
        endTrigger: '.bilic-article-template__content__form',
        end: 'center bottom',
        pin: true,
        pinSpacing: false,
        // markers: true,
        id: 'pin',
        invalidateOnRefresh: true,
      });
    });


    // const lenis = new Lenis({
    //   duration: 1.2,
    //   easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    //   direction: 'vertical', // vertical, horizontal
    //   gestureDirection: 'vertical', // vertical, horizontal, both
    //   smooth: true,
    //   mouseMultiplier: 1,
    //   smoothTouch: false,
    //   touchMultiplier: 2,
    //   infinite: false,
    // });

    // function raf(time) {
    //   lenis.raf(time)
    //   requestAnimationFrame(raf)
    // }

    // requestAnimationFrame(raf);

    // const lenisInstance = new Lenis({
    //   wrapper: document.querySelector("body"), // element which has overflow
    //   content: document.querySelector("main") // usually wrapper's direct child
    // });




  }, [t]);



  return (
    <>
      <Annoncement styleModifier="landing">
        <LandingAnnouncementContent />
      </Annoncement>

      <ArticleTemplate ArticleTemplate
      articletemplateherobgimgs={
        <ArticleTemplateHeroBgImgs ArticleTemplateHeroBgImgs ></ArticleTemplateHeroBgImgs >
      }
      articletemplatehero={<ArticleTemplateHero ArticleTemplateHero ></ArticleTemplateHero >}
        articletemplateheroposition="center"
      articletemplateheroillustration={<ArticleTemplateHeroIllustration ArticleTemplateHeroIllustration ></ArticleTemplateHeroIllustration >}
      templatename="bilic-article-template-neo">
      <div className="c-glow">
        <div className="c-glow__wrap">
          <div className="c-glow c-glow--one"></div>
          <div className="c-glow c-glow--two"></div>
        </div>
        {/* <div class="c-glow__wrap c-glow__wrap--two">
      <div class="c-glow c-glow--two"></div>
		</div> */}
      </div>

      <div className="" ref={tl}>

        <section className="bilic-landing__banner__manifest__advisors d-flex flex-column">
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column align-items-md-center">
                <h4>Backed by</h4>
                <div className="bilic-landing__banner__manifest__advisors__slide">
                  <img src={AdvisorImageAmazon} alt="" />
                  <img src={AdvisorImageBarclay} alt="" />
                  <img src={AdvisorImagePlexal} alt="" />
                  <img src={AdvisorImageCyber} alt="" />
                  {/* <img src={AdvisorImageAirbus} alt="" /> */}
                  {/* <img src={AdvisorImageMass} alt="" /> */}
                </div>
              </div>
            </div>
          </div>
        </section>

          {/* <section className="bilic-landing__number-wrap">
            <div className="container">
              <section className="bilic-landing__number">
                <div
                  className="bilic-landing__number__item"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h4>
                    <span>$11.7</span> Billion
                  </h4>
                  <p>
                    The annual global spending on blockchain solutions by 2022.
                  </p>
                </div>
                <div
                  className="bilic-landing__number__item"
                  data-aos="fade-up"
                  data-aos-delay="105"
                >
                  <h4>
                    <span>$513</span> Million
                  </h4>
                  <p>
                    Value of Crypto currency theft worldwide from 2016 to 2020
                  </p>
                </div>
                <div
                  className="bilic-landing__number__item"
                  data-aos="fade-up"
                  data-aos-delay="110"
                >
                  <h4>
                    <span>$406</span> Million
                  </h4>
                  <p>
                    Value of cryptocurrency received by known ransomware
                    addresses.
                  </p>
                </div>
                <div
                  className="bilic-landing__number__item"
                  data-aos="fade-up"
                  data-aos-delay="120"
                >
                  <h4>
                    <span>$5.6</span> Trillion
                  </h4>
                  <p>Global blockchain market by 2030</p>
                </div>
              </section>
            </div>
          </section> */}


        <section className="bilic-article-template__content__solutions">
          <div className="container">
            <div className="bilic-article-template__content__solutions__matrix">
              <img src={MatrixLineOne} alt="matrix line" className="bilic-article-template__content__solutions__matrix-one" />
              <img src={MatrixLineTwo} alt="matrix line" className="bilic-article-template__content__solutions__matrix-two" />
              <img src={MatrixLineThree} alt="matrix line" className="bilic-article-template__content__solutions__matrix-three" />
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                <header className="bilic-article-template__content__heading">
                  <h4>What can NEO, our <span> AI Agent </span> do?</h4>
                  <p>
                    Intelligence generated from a wealth of data, machines that learn and our community
                  </p>
                </header>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bilic-article-template__content__solutions-wrap">
                  <section className="bilic-article-template__content__solutions__list">
                    <article className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoSearchTrans} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Transaction Monitoring Agent
                        </h3>
                        <p>
                          Detects unusual transaction patterns and flags potential instances of money laundering, fraud, or other illicit activities.
                        </p>
                      </div>
                    </article>
                    <article className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoUser} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Customer Due Diligence Agent
                        </h3>
                        <p>
                          Assists in performing enhanced due diligence and ensuring compliance with KYC regulations.
                        </p>
                      </div>
                    </article>
                    <article className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoSecuritytIcon} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Risk Assessment Agent
                        </h3>
                        <p>
                          Provides an intelligent solution for assessing the risk associated with specific customers, accounts, or transactions.
                        </p>
                      </div>
                    </article>
                  </section>
                </div>
              </div>

              <div className="col-12">
                <div className="bilic-article-template__content__solutions-wrap wraplast">

                  <div className="bilic-article-template__content__solutions__list">
                    <div className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoWalletIcon} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Anti-Money Laundering (AML) Compliance Agent
                        </h3>
                        <p>
                          Utilizes AI algorithms to detect and prevent money laundering activities within traditional financial systems.
                        </p>
                      </div>
                    </div>
                    <div className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoCheckIcon} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Fraud Detection and Prevention Agent
                        </h3>
                        <p>
                          Employs advanced machine learning techniques to identify and mitigate fraudulent activities within traditional finance.
                        </p>

                      </div>
                    </div>
                    <div className="bilic-article-template__content__solutions__list__item">
                      <figure>
                        <img src={NeoDataIcon} alt="" />
                      </figure>
                      <div className="bilic-article-template__content__solutions__list__item__card">
                        <h3>
                          Regulatory Compliance Check Agent
                        </h3>
                        <p>
                          Automatically cross-references transactions, customer profiles, and activities against regulatory requirements.
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bilic-article-template__content__tagline">
          <div className="container">
            <div className="row">
              <div className="col-11">
                <header>
                  <h5 className="bilic-article-template__content__tagline__heading">
                    By harnessing the power of Large Language Models, we have
                    built agents that "think" and "act" as security and compliance
                    officers. In a matter of seconds, our agents can absorb and access
                    financial datasets from multiple sources, perform analysis, and
                    execute tasks</h5>
                </header>
              </div>
            </div>
          </div>
        </section>

        <section className="bilic-article-template__content__howto">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-11">
                <header className="bilic-article-template__content__howto__header">
                  <h2>Steps at the forefront</h2>
                  <h4>The Future of <br /> security and compliance</h4>
                  <p>Bilic’s Neo AI Agents are designed to address these challenges.
                    By harnessing the power of Large Language Models, we have
                    built agents that "think" and "act" as a security and compliance
                    officers. In a matter of seconds, our agents can absorb and assess</p>
                </header>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-11">
                <div className="row g-5">
                  <div className="col-lg-6 mb-3">
                    <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--one">
                      <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="37.0001" cy="18.1331" rx="14.3556" ry="9.06667" fill="#63AF15" />
                        <g filter="url(#filter0_bdii_2215_121429)">
                          <circle cx="37" cy="34" r="34" fill="url(#paint0_linear_2215_121429)" />
                        </g>
                        <path d="M48.9627 28.9952V33.4996L47.7595 32.8054C46.7722 32.2346 45.5382 32.4043 44.736 33.1756C44.5663 33.3299 44.4275 33.515 44.3041 33.7155L44.2115 33.8852C44.0419 34.1783 43.903 34.2554 43.8876 34.2709C43.8567 34.2554 43.7025 34.1783 43.5328 33.8698L43.4403 33.7155C43.1009 33.1293 42.561 32.7283 41.9131 32.5586C41.2498 32.3735 40.571 32.466 40.0003 32.8054L38.5965 33.623C37.964 33.9778 37.5167 34.5639 37.3316 35.2581C37.1465 35.9677 37.239 36.6927 37.5938 37.3252C37.7635 37.6029 37.7635 37.7725 37.7635 37.8034C37.7481 37.8188 37.5938 37.9114 37.2545 37.9114C35.7581 37.9114 34.5395 39.13 34.5395 40.6109V42.0609C34.5395 43.5418 35.7581 44.7605 37.2545 44.7605C37.5938 44.7605 37.7481 44.853 37.7481 44.8685C37.7635 44.8839 37.7635 45.069 37.5938 45.3467C37.4858 45.5472 37.3933 45.7477 37.347 45.9637H31.9942C27.7367 45.9637 24.2812 42.5083 24.2812 38.2507V28.9952C24.2812 24.7376 27.7367 21.2822 31.9942 21.2822H41.2498C45.5073 21.2822 48.9627 24.7376 48.9627 28.9952Z" fill="url(#paint1_linear_2215_121429)" fillOpacity="0.24" />
                        <path d="M44.7363 30.1356V33.1745C44.5666 33.3287 44.4278 33.5138 44.3044 33.7144L44.2118 33.884C44.0422 34.1771 43.9033 34.2543 43.8879 34.2697C43.8571 34.2543 43.7028 34.1771 43.5331 33.8686L43.4406 33.7144C43.1012 33.1282 42.5613 32.7271 41.9134 32.5574C41.2501 32.3723 40.5713 32.4649 40.0006 32.8042L38.5968 33.6218C37.9643 33.9766 37.517 34.5628 37.3319 35.257C37.1468 35.9665 37.2393 36.6916 37.5941 37.324C37.7638 37.6017 37.7638 37.7714 37.7638 37.8022C37.7484 37.8177 37.5941 37.9102 37.2548 37.9102C35.7584 37.9102 34.5398 39.1289 34.5398 40.6097V41.7358H33.1361C30.5908 41.7358 28.5083 39.6533 28.5083 37.1081V30.1356C28.5083 27.5903 30.5908 25.5078 33.1361 25.5078H40.1086C42.6538 25.5078 44.7363 27.5903 44.7363 30.1356Z" fill="white" fillOpacity="0.86" />
                        <path d="M32.0878 19.3532V21.2815H31.9953C31.224 21.2815 30.4835 21.3895 29.7739 21.6054V19.3532C29.7739 18.7208 30.283 18.1963 30.9309 18.1963C31.5633 18.1963 32.0878 18.7208 32.0878 19.3532Z" fill="white" />
                        <path d="M37.7792 19.3532V21.2815H35.4653V19.3532C35.4653 18.7208 35.9898 18.1963 36.6223 18.1963C37.2547 18.1963 37.7792 18.7208 37.7792 19.3532Z" fill="white" />
                        <path d="M37.0473 47.8665V45.9383H34.7334V47.8665C34.7334 48.499 35.2579 49.0234 35.8903 49.0234C36.5228 49.0234 37.0473 48.499 37.0473 47.8665Z" fill="white" />
                        <path d="M43.4858 19.3532V21.6054C42.7762 21.3895 42.0203 21.2815 41.249 21.2815H41.1719V19.3532C41.1719 18.7208 41.6964 18.1963 42.3288 18.1963C42.9613 18.1963 43.4858 18.7208 43.4858 19.3532Z" fill="white" />
                        <path d="M52.0468 27.9138C52.0468 28.5617 51.5378 29.0707 50.8899 29.0707H48.9616V28.9936C48.9616 28.2223 48.8537 27.4664 48.6377 26.7568H50.8899C51.5378 26.7568 52.0468 27.2813 52.0468 27.9138Z" fill="white" fillOpacity="0.62" />
                        <path d="M32.0878 45.9626V47.8909C32.0878 48.5233 31.5633 49.0478 30.9309 49.0478C30.283 49.0478 29.7739 48.5233 29.7739 47.8909V45.6387C30.4835 45.8546 31.224 45.9626 31.9953 45.9626H32.0878Z" fill="white" />
                        <path d="M24.6054 26.7568C24.3895 27.4664 24.2815 28.2223 24.2815 28.9936V29.0707H22.3532C21.7208 29.0707 21.1963 28.5617 21.1963 27.9138C21.1963 27.2813 21.7208 26.7568 22.3532 26.7568H24.6054Z" fill="white" />
                        <path d="M24.2815 32.4648V34.7787H22.3532C21.7208 34.7787 21.1963 34.2543 21.1963 33.6218C21.1963 32.9739 21.7208 32.4648 22.3532 32.4648H24.2815Z" fill="white" />
                        <path d="M24.6054 40.4877H22.3532C21.7208 40.4877 21.1963 39.9632 21.1963 39.3308C21.1963 38.6829 21.7208 38.1738 22.3532 38.1738H24.2815V38.251C24.2815 39.0223 24.3895 39.7781 24.6054 40.4877Z" fill="white" />
                        <path d="M50.4907 39.0658C49.4418 39.0658 48.8402 38.5413 48.8402 37.77C48.8402 37.4615 48.9327 37.1067 49.1487 36.7365C49.5806 35.9961 49.3184 35.0397 48.5779 34.6232L47.1742 33.8056C46.5263 33.4199 45.6933 33.6513 45.3076 34.2992L45.2151 34.4535C44.4746 35.7338 43.2714 35.7338 42.531 34.4535L42.4384 34.2992C42.0682 33.6513 41.2197 33.4199 40.5873 33.8056L39.1681 34.6232C38.6745 34.9008 38.3814 35.4253 38.3814 35.9652C38.3814 36.2274 38.4585 36.4897 38.5973 36.7365C38.8133 37.1067 38.9213 37.4615 38.9213 37.77C38.9213 38.5413 38.3042 39.0658 37.2553 39.0658C36.4069 39.0658 35.6973 39.76 35.6973 40.6084V42.0584C35.6973 42.9069 36.4069 43.601 37.2553 43.601C38.3042 43.601 38.9213 44.1255 38.9213 44.8968C38.9213 45.2053 38.8133 45.5601 38.5973 45.9304C38.1654 46.6708 38.4277 47.6272 39.1681 48.0437L40.5873 48.8613C41.2197 49.2469 42.0682 49.0155 42.4384 48.3677L42.531 48.2134C43.2714 46.933 44.4746 46.933 45.2151 48.2134L45.3076 48.3677C45.6933 49.0155 46.5263 49.2469 47.1742 48.8613L48.5779 48.0437C49.0715 47.766 49.3646 47.2416 49.3646 46.7017C49.3646 46.4394 49.2875 46.1772 49.1487 45.9304C48.9327 45.5601 48.8402 45.2053 48.8402 44.8968C48.8402 44.1255 49.4418 43.601 50.4907 43.601C51.3546 43.601 52.0487 42.9069 52.0487 42.0584V40.6084C52.0487 39.76 51.3546 39.0658 50.4907 39.0658ZM43.873 43.9867C42.4075 43.9867 41.2197 42.7989 41.2197 41.3334C41.2197 39.868 42.4075 38.6802 43.873 38.6802C45.3385 38.6802 46.5263 39.868 46.5263 41.3334C46.5263 42.7989 45.3385 43.9867 43.873 43.9867Z" fill="url(#paint2_linear_2215_121429)" fillOpacity="0.67" />
                        <path d="M46.5262 41.3359C46.5262 42.8013 45.3384 43.9891 43.873 43.9891C42.4075 43.9891 41.2197 42.8013 41.2197 41.3359C41.2197 39.8704 42.4075 38.6826 43.873 38.6826C45.3384 38.6826 46.5262 39.8704 46.5262 41.3359Z" fill="white" />
                        <path opacity="0.3" d="M57.3998 14.3555L57.6477 15.6187L58.9109 15.8666L57.6477 16.1145L57.3998 17.3777L57.1519 16.1145L55.8887 15.8666L57.1519 15.6187L57.3998 14.3555Z" fill="white" />
                        <path d="M21.889 55.9102L22.1369 57.1734L23.4002 57.4213L22.1369 57.6692L21.889 58.9324L21.6411 57.6692L20.3779 57.4213L21.6411 57.1734L21.889 55.9102Z" fill="white" />
                        <circle opacity="0.4" cx="65.3329" cy="44.9549" r="0.377778" fill="white" />
                        <defs>
                          <filter id="filter0_bdii_2215_121429" x="-46.5965" y="-49.5965" width="167.193" height="167.193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.7983" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2215_121429" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3.96772" />
                            <feGaussianBlur stdDeviation="1.4879" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="effect1_backgroundBlur_2215_121429" result="effect2_dropShadow_2215_121429" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2215_121429" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2215_121429" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect3_innerShadow_2215_121429" result="effect4_innerShadow_2215_121429" />
                          </filter>
                          <linearGradient id="paint0_linear_2215_121429" x1="37" y1="0" x2="37" y2="68" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#34412C" />
                            <stop offset="0.8125" stopColor="#2A2D2B" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_2215_121429" x1="36.622" y1="21.2822" x2="36.622" y2="45.9637" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint2_linear_2215_121429" x1="43.873" y1="33.6182" x2="43.873" y2="49.0487" gradientUnits="userSpaceOnUse">
                            <stop offset="0.015625" stopColor="white" />
                            <stop offset="0.989583" stopColor="white" stopOpacity="0.1" />
                          </linearGradient>
                        </defs>
                      </svg>



                      <h6>Efficiency</h6>
                      <p>
                        NEO agents can analyze vast amounts of data rapidly and accurately, automating routine tasks and minimizing human error. This means you can focus on what matters most, while NEO takes care of the rest.
                      </p>
                      <figure>
                        <img src={NeoTransactionCard} alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--two">
                      <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="37.0001" cy="18.1331" rx="14.3556" ry="9.06667" fill="#63AF15" />
                        <g filter="url(#filter0_bdii_2221_121440)">
                          <circle cx="37" cy="34" r="34" fill="url(#paint0_linear_2221_121440)" />
                        </g>
                        <path d="M38.8499 17.0502C38.8499 15.7523 37.7978 14.7002 36.4999 14.7002C35.202 14.7002 34.1499 15.7523 34.1499 17.0502V19.4002C34.1499 20.6981 35.202 21.7502 36.4999 21.7502C37.7978 21.7502 38.8499 20.6981 38.8499 19.4002V17.0502Z" fill="url(#paint1_linear_2221_121440)" fillOpacity="0.24" />
                        <path d="M49.7934 23.5283C50.7111 22.6106 50.7111 21.1226 49.7934 20.2049C48.8758 19.2872 47.3877 19.2872 46.4701 20.2049L44.8084 21.8666C43.8907 22.7843 43.8907 24.2723 44.8084 25.19C45.726 26.1077 47.2141 26.1077 48.1317 25.19L49.7934 23.5283Z" fill="url(#paint2_linear_2221_121440)" />
                        <path d="M55.3 33.5004C55.3 34.7983 54.2479 35.8504 52.95 35.8504H50.6C49.3021 35.8504 48.25 34.7983 48.25 33.5004C48.25 32.2025 49.3021 31.1504 50.6 31.1504H52.95C54.2479 31.1504 55.3 32.2025 55.3 33.5004Z" fill="url(#paint3_linear_2221_121440)" fillOpacity="0.24" />
                        <path d="M24.8686 25.19C25.7863 26.1077 27.2742 26.1077 28.1919 25.19C29.1097 24.2723 29.1097 22.7843 28.1919 21.8666L26.5303 20.2049C25.6125 19.2872 24.1246 19.2872 23.2068 20.2049C22.2891 21.1226 22.2891 22.6106 23.2068 23.5283L24.8686 25.19Z" fill="url(#paint4_linear_2221_121440)" />
                        <path d="M24.7502 33.5004C24.7502 34.7983 23.6981 35.8504 22.4002 35.8504H20.0502C18.7523 35.8504 17.7002 34.7983 17.7002 33.5004C17.7002 32.2025 18.7523 31.1504 20.0502 31.1504H22.4002C23.6981 31.1504 24.7502 32.2025 24.7502 33.5004Z" fill="url(#paint5_linear_2221_121440)" fillOpacity="0.24" />
                        <path d="M31.7998 47.6V45.25H41.1998V47.6C41.1998 50.1958 39.0956 52.3 36.4998 52.3C33.9041 52.3 31.7998 50.1958 31.7998 47.6Z" fill="url(#paint6_linear_2221_121440)" fillOpacity="0.24" />
                        <path d="M41.2022 42.8996C41.2365 42.0999 41.688 41.3808 42.3206 40.8812C44.5009 39.1598 45.9001 36.493 45.9001 33.4996C45.9001 28.3081 41.6915 24.0996 36.5001 24.0996C31.3086 24.0996 27.1001 28.3081 27.1001 33.4996C27.1001 36.493 28.4994 39.1598 30.6796 40.8812C31.3123 41.3808 31.7637 42.0999 31.798 42.8996H41.2022Z" fill="url(#paint7_linear_2221_121440)" fillOpacity="0.24" />
                        <path d="M37.425 32.9688V28.7998L32.7407 35.0507H35.3431V39.2145L40.0274 32.9688H37.425Z" fill="white" />
                        <path d="M31.5 45.3C31.5 45.1343 31.6343 45 31.8 45H41.2C41.3657 45 41.5 45.1343 41.5 45.3V45.7C41.5 45.8657 41.3657 46 41.2 46H31.8C31.6343 46 31.5 45.8657 31.5 45.7V45.3Z" fill="url(#paint8_linear_2221_121440)" />
                        <path opacity="0.3" d="M57.3998 14.3555L57.6477 15.6187L58.9109 15.8666L57.6477 16.1145L57.3998 17.3777L57.1519 16.1145L55.8887 15.8666L57.1519 15.6187L57.3998 14.3555Z" fill="white" />
                        <path d="M21.889 55.9102L22.1369 57.1734L23.4002 57.4213L22.1369 57.6692L21.889 58.9324L21.6411 57.6692L20.3779 57.4213L21.6411 57.1734L21.889 55.9102Z" fill="white" />
                        <circle opacity="0.4" cx="65.3329" cy="44.9549" r="0.377778" fill="white" />
                        <defs>
                          <filter id="filter0_bdii_2221_121440" x="-46.5965" y="-49.5965" width="167.193" height="167.193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.7983" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2221_121440" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3.96772" />
                            <feGaussianBlur stdDeviation="1.4879" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="effect1_backgroundBlur_2221_121440" result="effect2_dropShadow_2221_121440" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2221_121440" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2221_121440" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect3_innerShadow_2221_121440" result="effect4_innerShadow_2221_121440" />
                          </filter>
                          <linearGradient id="paint0_linear_2221_121440" x1="37" y1="0" x2="37" y2="68" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#34412C" />
                            <stop offset="0.8125" stopColor="#2A2D2B" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_2221_121440" x1="36.4999" y1="14.7002" x2="36.4999" y2="21.7502" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint2_linear_2221_121440" x1="47.3009" y1="19.5166" x2="47.3009" y2="25.8783" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint3_linear_2221_121440" x1="51.775" y1="31.1504" x2="51.775" y2="35.8504" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint4_linear_2221_121440" x1="25.6994" y1="19.5166" x2="25.6994" y2="25.8783" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint5_linear_2221_121440" x1="21.2252" y1="31.1504" x2="21.2252" y2="35.8504" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint6_linear_2221_121440" x1="36.4998" y1="45.25" x2="36.4998" y2="52.3" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                          <linearGradient id="paint7_linear_2221_121440" x1="36.5001" y1="24.0996" x2="36.5001" y2="42.8996" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0001" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.76" />
                          </linearGradient>
                          <linearGradient id="paint8_linear_2221_121440" x1="43.5" y1="44.5" x2="33" y2="45.5" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" stopOpacity="0.25" />
                            <stop offset="1" stopColor="white" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <h6>Adaptability</h6>
                      <p>
                        NEO agents are continuously learning and adapting to evolving security threats, becoming invaluable assets in safeguarding your valuable assets and reducing the likelihood of compliance breaches.
                      </p>
                      <figure>
                        <img src={RouteCardSVG} alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--three">
                      <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="36.9991" cy="18.1331" rx="14.3556" ry="9.06667" fill="#63AF15" />
                        <g filter="url(#filter0_bdii_2221_121461)">
                          <circle cx="36.999" cy="34" r="34" fill="url(#paint0_linear_2221_121461)" />
                        </g>
                        <path opacity="0.3" d="M57.3988 14.3555L57.6467 15.6187L58.9099 15.8666L57.6467 16.1145L57.3988 17.3777L57.1509 16.1145L55.8877 15.8666L57.1509 15.6187L57.3988 14.3555Z" fill="white" />
                        <path d="M21.8881 55.9102L22.136 57.1734L23.3992 57.4213L22.136 57.6692L21.8881 58.9324L21.6402 57.6692L20.377 57.4213L21.6402 57.1734L21.8881 55.9102Z" fill="white" />
                        <circle opacity="0.4" cx="65.3319" cy="44.9549" r="0.377778" fill="white" />
                        <rect x="21.9995" y="19" width="19.5946" height="19.5946" rx="5.4865" fill="url(#paint1_linear_2221_121461)" fillOpacity="0.24" />
                        <rect x="32.189" y="28.4062" width="19.5946" height="19.5946" rx="5.4865" fill="#D9D9D9" />
                        <path d="M28.8502 48.9453C28.8238 49.1409 28.6437 49.2787 28.4489 49.2465C26.5048 48.9251 24.6678 48.1294 23.1015 46.9277C21.5353 45.7259 20.2912 44.1575 19.4774 42.3629C19.3959 42.1831 19.4823 41.9735 19.6645 41.8973V41.8973C19.8466 41.8212 20.0555 41.9074 20.1373 42.0871C20.9028 43.7667 22.0694 45.2347 23.5367 46.3605C25.004 47.4863 26.724 48.2331 28.5445 48.5377C28.7392 48.5702 28.8765 48.7496 28.8502 48.9453V48.9453Z" fill="url(#paint2_linear_2221_121461)" />
                        <path d="M42.6845 16.5948C42.7109 16.3991 42.891 16.2614 43.0858 16.2936C45.0299 16.615 46.8669 17.4106 48.4331 18.6124C49.9994 19.8141 51.2435 21.3825 52.0572 23.1771C52.1387 23.3569 52.0523 23.5666 51.8702 23.6427V23.6427C51.688 23.7188 51.4792 23.6326 51.3973 23.453C50.6319 21.7734 49.4653 20.3054 47.998 19.1795C46.5307 18.0537 44.8107 17.3069 42.9902 17.0024C42.7955 16.9698 42.6581 16.7904 42.6845 16.5948V16.5948Z" fill="url(#paint3_linear_2221_121461)" />
                        <defs>
                          <filter id="filter0_bdii_2221_121461" x="-46.5975" y="-49.5965" width="167.193" height="167.193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.7983" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2221_121461" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3.96772" />
                            <feGaussianBlur stdDeviation="1.4879" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="effect1_backgroundBlur_2221_121461" result="effect2_dropShadow_2221_121461" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2221_121461" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2221_121461" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect3_innerShadow_2221_121461" result="effect4_innerShadow_2221_121461" />
                          </filter>
                          <linearGradient id="paint0_linear_2221_121461" x1="36.999" y1="0" x2="36.999" y2="68" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#34412C" />
                            <stop offset="0.8125" stopColor="#2A2D2B" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_2221_121461" x1="31.7968" y1="19" x2="31.7968" y2="38.5946" gradientUnits="userSpaceOnUse">
                            <stop offset="0.432292" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.25" />
                          </linearGradient>
                          <linearGradient id="paint2_linear_2221_121461" x1="18.407" y1="37.5364" x2="42.4057" y2="37.278" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                          </linearGradient>
                          <linearGradient id="paint3_linear_2221_121461" x1="53.1276" y1="28.0036" x2="29.129" y2="28.2621" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>

                      <h6>Scalability</h6>
                      <p>
                        NEO allows you to deploy advanced software while keeping your teams agile and cost-effective. Our solution is designed to work on all devices, so you only have to set it up once and enjoy beautiful results forever.
                      </p>
                      <figure>
                        <img src={MetricsCardSVG} alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--four">
                      <svg width="74" height="75" viewBox="0 0 74 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="37.0001" cy="18.1331" rx="14.3556" ry="9.06667" fill="#63AF15" />
                        <g filter="url(#filter0_bdii_2221_121482)">
                          <circle cx="37" cy="34" r="34" fill="url(#paint0_linear_2221_121482)" />
                        </g>
                        <path opacity="0.3" d="M57.3998 14.3555L57.6477 15.6187L58.9109 15.8666L57.6477 16.1145L57.3998 17.3777L57.1519 16.1145L55.8887 15.8666L57.1519 15.6187L57.3998 14.3555Z" fill="white" />
                        <path d="M21.889 55.9102L22.1369 57.1734L23.4002 57.4213L22.1369 57.6692L21.889 58.9324L21.6411 57.6692L20.3779 57.4213L21.6411 57.1734L21.889 55.9102Z" fill="white" />
                        <circle opacity="0.4" cx="65.3329" cy="44.9549" r="0.377778" fill="white" />
                        <path d="M45.8473 31.7916H40.958V20.4023C40.958 17.7564 39.5199 17.2099 37.7655 19.1944L36.5001 20.6324L25.801 32.7983C24.3342 34.4664 24.9382 35.8181 27.1527 35.8181H32.0422V47.2073C32.0422 49.8533 33.4802 50.3997 35.2346 48.4153L36.5001 46.9772L47.199 34.8115C48.6658 33.1434 48.0618 31.7916 45.8473 31.7916Z" fill="url(#paint1_linear_2221_121482)" fillOpacity="0.35" />
                        <defs>
                          <filter id="filter0_bdii_2221_121482" x="-46.5965" y="-49.5965" width="167.193" height="167.193" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="24.7983" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2221_121482" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3.96772" />
                            <feGaussianBlur stdDeviation="1.4879" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                            <feBlend mode="normal" in2="effect1_backgroundBlur_2221_121482" result="effect2_dropShadow_2221_121482" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2221_121482" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="shape" result="effect3_innerShadow_2221_121482" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-0.495965" />
                            <feGaussianBlur stdDeviation="0.247983" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="effect3_innerShadow_2221_121482" result="effect4_innerShadow_2221_121482" />
                          </filter>
                          <linearGradient id="paint0_linear_2221_121482" x1="37" y1="0" x2="37" y2="68" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#34412C" />
                            <stop offset="0.8125" stopColor="#2A2D2B" />
                          </linearGradient>
                          <linearGradient id="paint1_linear_2221_121482" x1="36.5" y1="18" x2="36.5" y2="49.6097" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="0.0001" stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0.76" />
                          </linearGradient>
                        </defs>
                      </svg>
                        <h6>Unrivaled Security</h6>
                      <p style={{maxWidth: '440px'}}>
                          Neo employs cutting-edge measures to safeguard your sensitive data.
                      </p>
                      <figure>
                        <img src={NeoBars} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="col-lg-6">
              <div className="bilic-article-template__content__howto__grid">
                <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--two">
                  <h4>NEO, our AI Agent do?</h4>
                </div>
                <div className="bilic-article-template__content__howto__steps bilic-article-template__content__howto__steps--three">
                  <h4>NEO, our AI Agent do?</h4>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </section>


        <div ref={tl} className="triggerstart" style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>

          <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
            <div className="container">
              {/* <img src={BannerSwirl} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
              <div className="row justify-content-center">
                <div className="col">
                  <header>
                    {/* <div className="bilic__glow"></div> */}
                    <h4>What can NEO, our <span> AI Agent </span> do?</h4>
                    <p>
                      Intelligence generated from a wealth of data, machines that learn and our community
                    </p>
                  </header>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-11">
                  <div className="bilic-article-template__content__teaser-wrap stack">
                    <div className="bilic-article-template__content__teaser__text">

                      <h3>Can I use NEO to enhance my existing <span> compliance processes </span> ?</h3>
                      <p>
                        Absolutely! NEO is designed to integrate seamlessly with your existing compliance processes, providing advanced capabilities in tracking, analyzing, and investigating financial activities.

                      </p>
                    </div>
                    <div className="bilic-article-template__content__teaser__illustration">
                      <img className="img-fluid" src={NeoStacks} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
            </div>
          </section>

          <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-righttext">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-11">
                  <div className="bilic-article-template__content__teaser-wrap stack">
                    <div className="bilic-article-template__content__teaser__illustration">
                      <img className="img-fluid" src={NeoCaps} alt="" />
                    </div>
                    <div className="bilic-article-template__content__teaser__text">
                      <h3>
                        Are NEO agents customizable to fit my specific <span> needs </span> ?
                      </h3>
                      <p style={{ maxWidth: 'initial' }}>
                        Yes! NEO agents can be tailored to fit your specific compliance needs in various areas, including transaction monitoring, customer due diligence, risk assessment, anti-money laundering, fraud detection, regulatory compliance checks, and investigative intelligence.
                      </p>
                      {/* <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section hidden className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext" style={{ background: 'none' }}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-11">
                  <div className="bilic-article-template__content__teaser-wrap stack">
                    <div className="bilic-article-template__content__teaser__text">
                      <h3>
                        Can I use NEO across multiple  <span> teams and departments </span> ?
                      </h3>
                      <p style={{ maxWidth: 'initial' }}>
                        Yes! NEO can be deployed across multiple teams and departments, providing a unified solution for finance compliance and investigation.
                      </p>
                      {/* <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a> */}
                    </div>
                    <div className="bilic-article-template__content__teaser__illustration">
                      <img className="img-fluid" src={NeoCaps} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>


        <section hidden className="bilic-article-template__content__solutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bilic-article-template__content__solutions-wrap">
                  <div className="bilic-article-template__content__solutions__header">
                    <h4 style={{ maxWidth: "100%" }}>Ready to Transform Your Compliance and Investigation Processes?</h4>
                  </div>
                  <div className="bilic-article-template__content__solutions__list">
                    <div className="bilic-article-template__content__solutions__list__item">
                      <img src={FTMSense} alt="" />
                      <h3>
                        Investigating Cybercrimes in the Age of Decentralisation
                      </h3>
                      <p>
                        Blockchain forensics{/**(the use of scientific methods to manipulate data to create useful and informative descriptions of the manipulated data)**/} takes data from the blockchain to interpret the flow of digital assets
                      </p>
                    </div>
                    <div className="bilic-article-template__content__solutions__list__item">
                      <img src={FTMSense} alt="" />
                      <h3>
                        Benefits of Blockchain Forensics to the United Arab Emirates
                      </h3>
                      <p>
                        On May 27, 2021, the Dubai Electronic Security Center issued a press release stating that the new cryptocurrency- Dubai Coin
                      </p>
                    </div>
                    <div className="bilic-article-template__content__solutions__list__item">
                      <img src={FTMSense} alt="" />
                      <h3>
                        Sophisticated Money Laundering Instruments on the Blockchain
                      </h3>
                      <p>
                        A significant part of the global economy is immersed in dirty practices which make them illegal proceeds from illicit business transactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section hidden
          className="bilic-landing__cta bilic-article-template__demo"
          style={{ padding: "7rem 0" }}
        >
          <div className="container">
            <div className="">
              <div className="row justify-content-center text-center">
                <div className="col">
                  <header className="bilic-landing__cta__header">
                    <h2>Book a Demo</h2>
                    <p>
                      {" "}
                      Talk with one of our experts about Bilic's product and service
                      offerings
                    </p>
                    <a href="https://calendly.com/saminated/60-minutes" className="btn">
                      <span> Demo </span>{" "}
                    </a>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ArticleTemplate >
    </>

  );
}

export default NEOArticle;
