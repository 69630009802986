import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import BannerImageRight from "../images/bannerimage-binary.webp";
import BannerImageLeft from "../images/bannerimage-grid.png";
import BannerImageBV from "../images/verify-banner.svg";
import BVWallet from "../images/verify-wallet.svg";
import FTMSense from "../images/ftm-intelligence.svg";
import FTMPrint from "../images/ftm-print.svg";
import FTMCommunity from "../images/flow-community.webp";
import FTMLaw from "../images/ftm-law.svg";
import FTMFin from "../images/ftm-finance.svg";
import FTMCoin from "../images/ftm-coin.svg";
import Shield from "../images/shield.svg";
import "./index.scss";
import { Link } from "react-router-dom";

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={BannerImageRight}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-right"
    />
    <img
      src={BannerImageLeft}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-left"
    />
    <img
      src={BannerImageBV}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-bv"
    />
    <div className="bilic-article-template__glass bilic-article-template__glass-one d-none d-md-flex"></div>
    <div className="bilic-article-template__glass bilic-article-template__glass-two d-none d-md-flex"></div>
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      <span>Bilic Verify</span> <br className="d-lg-flex"/>Risk Scoring Engine
    </h5>
    <p>
      Bilic Verify is our novel AML tool for wallet risk factor check. Banks,
      crypto companies, custodians, exchanges, OTC desks, BTMs, fintechs and
      regtechs users can utilize Bilic Verify as a digital shield.{" "}
    </p>
    <div className="bilic-article-template__hero__links">
    <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>

      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>

    </div>

    <div className="launchlist-widget" data-key-id="LfUKSs" data-height="180px"></div>

  </>
);

const BVarticle = () => (
  <ArticleTemplate
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-bv"
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
  >
    <section className="bilic-article-template__content__tagline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11">
            <header className="">
              <h4>
                {" "}
                Verify <span>Wallet Risk</span> Factor
              </h4>
              <p>
                Bilic Verify serves as a digital shield. This solution protects
                consumers from sending their digital assets to a fraudulent
                wallet address that cannot be reversed.
              </p>
            </header>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={BVWallet} alt="" />
                  <h6>Verify Wallet Risk</h6>
                  <p>
                    Bilic verify can be used as a tool to verify wallet risk factor.
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={Shield} alt="" />
                  <h6>Digital Shield</h6>
                  <p>
                   Bilic verify can be used by banks, crypto companies, fintechs as a digital shield
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={FTMPrint} alt="" />
                  <h6>Anti-Money Laundering</h6>
                  <p>
                    Bilic verify can be used to prevent, detect, and report money laundering activities
                  </p>
                   <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Power of <br /> <span>Community</span>
                </h3>
                <p style={{ maxWidth: "533px" }}>
                  The platform uses the power of a community of experts like
                  blockchain analysts, blockchain developers, on-chain data
                  analysts, everyday traders and end-users to track,
                  investigate, and solve blockchain-related crimes through the
                  novel intel marketplace. By aggregating data from various 3rd
                  party nodes and off-chain sources the database Adding labels,
                </p>
                 <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={FTMCommunity} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4 style={{ maxWidth: "560px" }}>
                  Who needs the intelligence marketplace
                </h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMLaw} alt="" />
                  <h3>Law Enforcement and Investigation Companies</h3>
                  {/* <p>
                    Blockchain forensics
                    takes data from the blockchain to interpret the flow of
                    digital assets
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMFin} alt="" />
                  <h3>Financial Institutions and Compliance Officers</h3>
                  {/* <p>
                    On May 27, 2021, the Dubai Electronic Security Center issued
                    a press release stating that the new cryptocurrency- Dubai
                    Coin
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>Intelligence Generators</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMCoin} alt="" />
                  <h3>Crypto Business</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <section
      className="bilic-landing__cta bilic-article-template__demo"
      style={{ padding: "7rem 0" }}
    >
      <div className="container">
        <div className="">
          <div className="row justify-content-center text-center">
            <div className="col">
              <header className="bilic-landing__cta__header">
                <h2>Book a Demo</h2>
                <p>
                  {" "}
                  Talk with one of our experts about Bilic's product and service
                  offerings
                </p>
                <a href="https://calendly.com/saminated/60-minutes" className="btn">
                  <span> Demo </span>{" "}
                </a>
              </header>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </ArticleTemplate>
);

export default BVarticle;
