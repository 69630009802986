import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import "./index.scss";
import IDWBannerImage from "../images/binary.webp";
import IDWTransaction from "../images/transaction.svg";
import IDWLocation from "../images/location.svg";
import IDWSocial from "../images/social.svg";
import FTMCommunity from "../images/flow-community.webp";
import FTMSource from "../images/flow-source.webp";
import FTMSense from "../images/ftm-intelligence.svg";
import { Link } from "react-router-dom";

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={IDWBannerImage}
      alt=""
      className="bilic-article-template__hero__background"
    />
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      {/* Intelligence <span> Data </span> Warehouse */}
      Transaction <span> Risk </span> Monitoring
    </h5>
    <p>
      Bilic has created an Intelligence Gathering Platform that is revolutionizing how investigations are conducted on the blockchain by effectively utilizing collective intelligence.{" "}
    </p>
    <div className="bilic-article-template__hero__links">
      {/* <a
        className="btn bilic-article-template__hero__links__btn"
        target="_blank"
        href="https://www.docdroid.net/G52pJwT/billic-investor-pitch-pdf#page=16"
        rel="noreferrer"
      >
        Architecture
      </a>
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank"
        href="https://osf.io/dbf4n/"
        rel="noreferrer"
      >
        Research
      </a> */}

      <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>

      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>


    </div>
  </>
);

const IDWrticle = () => (
  <ArticleTemplate
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-idw"
  >

    <section className="bilic-article-template__content__tagline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11">
            <header className="">
              <h4><span>Track and Trace</span> the Blockchain</h4>
              <p>
                Intelligence generated from a wealth of data, machines that learn and our community
              </p>
            </header>
          </div>
        </div>
      </div>
    </section>



    <section className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IDWTransaction} alt="" />
                  <h6>Transaction inflow/outflow</h6>
                  <p>
                    Use explainable data linking, advanced AI and decision
                    models for regulatory compliance.
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IDWLocation} alt="" />
                  <h6>Location based data from IP and Timestamp</h6>
                  <p>
                    Use explainable data linking, advanced AI and decision
                    models for regulatory compliance.
                  </p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IDWSocial} alt="" />
                  <h6>Off-chain social media scrapping</h6>
                  <p>
                    Use explainable data linking, advanced AI and decision
                    models for regulatory compliance.
                  </p>
                  <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">

                <h3>Power of <br /> <span>Community</span></h3>
                <p style={{ maxWidth: "533px" }}>
                  The platform uses the power of a community of experts like
                  blockchain analysts, blockchain developers, on-chain data
                  analysts, everyday traders and end-users to track,
                  investigate, and solve blockchain-related crimes through the
                  novel intel marketplace.
                </p>
                <p style={{ maxWidth: "533px" }}>By aggregating data from various 3rd parties, nodes and off-chain sources the database, connections, labels and interest from various stakeholders can grow, when the guidelines and mindsets are agreed upon.
                </p>
                <p>Join the Crew, Investigate-to-Earn, analyze your own case or get a trainings here</p>
                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={FTMCommunity} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-righttext">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__illustration">
                <img className="img-fluid" src={FTMSource} alt="" />
              </div>
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  A variety of <span> sources </span>
                </h3>
                <p style={{ maxWidth: 'initial' }}>
                  The data is gathered from a variety of sources to create an Intelligence Data Warehouse (IDW) that no-one else can match. IDW is a critical component of the Bilic intelligence ecosystem since it allows for extensive high-level profiling, transaction segmentation, data scraping, and address tagging.
                </p>
                <p style={{ maxWidth: 'initial' }}>
                  The intelligence data warehouse consist of various intelligence indicators gathered with open-source and proprietary tools. Patterns can derived from anything like an IP Address, a projects website or transactions of all sizes and the matches with our database.
                </p>
                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4 style={{ maxWidth: "560px" }}>What resources are related to the data warehouse?</h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>
                    Investigating Cybercrimes in the Age of Decentralisation
                  </h3>
                  <p>
                    Blockchain forensics{/**(the use of scientific methods to manipulate data to create useful and informative descriptions of the manipulated data)**/} takes data from the blockchain to interpret the flow of digital assets
                  </p>
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>
                    Benefits of Blockchain Forensics to the United Arab Emirates
                  </h3>
                  <p>
                    On May 27, 2021, the Dubai Electronic Security Center issued a press release stating that the new cryptocurrency- Dubai Coin
                  </p>
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>
                    Sophisticated Money Laundering Instruments on the Blockchain
                  </h3>
                  <p>
                    A significant part of the global economy is immersed in dirty practices which make them illegal proceeds from illicit business transactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section hidden
      className="bilic-landing__cta bilic-article-template__demo"
      style={{ padding: "7rem 0" }}
    >
      <div className="container">
        <div className="">
          <div className="row justify-content-center text-center">
            <div className="col">
              <header className="bilic-landing__cta__header">
                <h2>Book a Demo</h2>
                <p>
                  {" "}
                  Talk with one of our experts about Bilic's product and service
                  offerings
                </p>
                <a href="https://calendly.com/saminated/60-minutes" className="btn">
                  <span> Demo </span>{" "}
                </a>
              </header>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ArticleTemplate>
);

export default IDWrticle;
