import React, { useEffect } from "react";
import ContactForm from "../contactform";
// import { Link } from "react-router-dom";
// import "./article-template.scss";
// import logo from "./logo.svg";

const ContactSection = () => {

  useEffect(() => {
    if (document) {
      const stylesheet = document.createElement("link");
      stylesheet.rel = "stylesheet";
      stylesheet.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

      document.head.appendChild(stylesheet);
    }
  }, []);


  return (
    <>
      <section
        className="bilic-article-template__content__form"
        id="#bilic-contact"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <header>
                <h4>
                  Curious How Bilic Can Transform Your Customer Support
                  Experience?{" "}
                </h4>
                <p>
                  Book your complimentary call and see how Bilic can help your
                  business and free up vital resources while offering 5-star
                  customer support.
                </p>
              </header>
            </div>

            <div className="col-10">
              <div className="App">
                <div className="py-6">
                  <ContactForm></ContactForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bilic-landing__cta">
        <div className="container">
          <div className="">
            <div className="row justify-content-center text-center">
              <div className="col">
                <header className="bilic-landing__cta__header">
                  <h2>Join the Bilic Community</h2>
                  <p>
                    {" "}
                    Want exclusive access to product updates, customer success
                    stories, and free resources?
                  </p>
                  <a href="https://discord.gg/Pc6EJCMSWk" className="btn">
                    <i className="fa-brands fa-discord" />{" "}
                    <span> Join our Discord channel.</span>{" "}
                  </a>
                </header>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}

export default ContactSection;
