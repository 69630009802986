import React from "react";
import { Link } from "react-router-dom";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import BannerImageLeft from "../images/bannerimage-grid.png";
import "./fundsarticle.scss";
// import IbrahimImage from "./images/ibrahim.jpg";

const ArticleTemplateHero = () => (
  <>
    <h5>
      <span>Funds Recovery </span> Services
    </h5>
    <p>
      If you have spent some money on a crypto investment platform before, and
      it turned out to be a fraudulent platform. We might be able to help you
      recover it.
    </p>
  </>
);

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={BannerImageLeft}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-right"
    />
    <img
      src={BannerImageLeft}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-left"
    />
  </>
);

const FundsArticle = () => (
  <ArticleTemplate
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-funds"
  >
    <section className="bilic-article-template__content__post">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-9">
            <h2>
              Our large community of on-chain analysts, investigators, data
              scientists and experts helps victims of fraud recover their crypto
              assets.
            </h2>
            <h3>
              <span>
                <Link to="https://ftm.bilic.io/follow-the-money">
                  {" "}
                  Bilic's FTM dashboard
                </Link>
              </span>{" "}
              speeds up the process of obtaining the location of your funds at
              the moment.
            </h3>

            <article>
              <h4 className="">Here's how bilic's funds tracker will </h4>

              <ul>
                <li>
                  Our investigators will help track your funds using our Funds
                  Tracker.
                </li>
                <li>
                  Help you communicate with the exchange in possession of your
                  funds at the moment. This means creating a report for you and
                  guiding you through the required steps.
                </li>
              </ul>

              <p>
                (Most times, a scammer transfers your money to an exchange, such
                as Binance or Coinbase. We will work hand-in-hand with this
                exchange to recover your funds.)
              </p>
            </article>

            <article>
              <h4>Why should your team help me?</h4>

              <ul>
                <li>
                  Your investigation gets done fast. At Bilic, there is a
                  community of data analysts and experts to help you.
                </li>
                <li>You're not relying on just 1 or 3 people to help you.</li>
                <li>
                  Having access to many investigators means another benefit for
                  you:
                </li>
                <li>
                  You might be frustrated that none wants to handle your case
                  because you lost less than $100,000.
                </li>
                <li>
                  We help people recover stolen funds starting at, $10,000 in
                  amount.
                </li>
              </ul>
            </article>

            <article>
              <h4 className="">Report a Case</h4>
              <p>
                Before you take this step, kindly go through the requirements
                below
              </p>
              <ul>
                <li>
                  The cryptocurrency in question must be worth at least $10,000.
                </li>
                <li>
                  You have transaction links (not screenshots) as proof that you
                  transferred a certain amount from your account to the
                  scammer's account.
                </li>
                <li>
                  You are able to get a police report in your locality, if
                  needed.
                </li>
                <li>
                  {" "}
                  The event happened within 6 months to the time of reporting.
                </li>
                <li>
                  You are willing to pay an upfront fee of $500. This fee is
                  required to carry out the first stages of your investigation.
                </li>
                <li>
                  You will provide 5% of the funds, if recovered. This is the
                  completion of your fee.
                </li>
              </ul>
            </article>

            <article>
              <h4>Pricing:</h4>
              <h5>$500 upfront</h5>
              <h5>5% of the total funds recovered.</h5>

              <h6>Please note:</h6>
              <ul>
                <li>
                  The $500 upfront is non-refundable. It is used to facilitate
                  the first stages of your investigation.
                </li>
                <li>
                  We do not offer a 100% guarantee of getting your funds back.
                  <p>The probability depends on:</p>
                  <ul>
                    <li>The exchange hosting your funds</li>
                    <li>Your ability to provide substantial proof</li>
                    <li>Other circumstances beyond our control.</li>
                  </ul>
                </li>
                <li>
                  You personally lost at least $10,000 from your wallet(s).{" "}
                </li>
              </ul>
            </article>

            <article>
              <p>
                For example, some victims invest $3000 in a platform and are
                promised $12,000 in ROI.
              </p>
              <p>
                Their investment account on the platform shows that they have
                $12,000, but cannot withdraw it.
              </p>

              <p>
                We would not attend to such a case because you didn't lose
                $12,000 from your wallet. You spent only $3,000 and "gained"
                $12,000 on the platform.
              </p>

              <p>
                If you have read and understood everything on this page, you can
                report your case below.
              </p>

              <p>
                A member of the Support Team will reach out to you within 24-48
                hours.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  </ArticleTemplate>
);

export default FundsArticle;
