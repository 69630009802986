import React from "react";
import "./announcement.scss";




const Annoncement = ({ children, styleModifier }) => (
  <section className={`c-announcement c-announcement--${styleModifier}`}>
    <div className="container-xl">
      <div className="row">
        <div className="c-announcement__content">
          {children}
        </div>
      </div>
    </div>
  </section>
);

export default Annoncement;