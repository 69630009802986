import React from "react";
import ArticleTemplate from "../../templates/articletemplate/articletemplate";
import "./index.scss";


const ContactArticle = () => (
  <ArticleTemplate
    // articletemplateherobgimgs={
    //   <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    // }
    // articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-contact"
  >

  </ArticleTemplate>
);

export default ContactArticle;
