import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import "./index.scss";
import IDWBannerImage from "../images/binary.webp";
import FTMSense from "../images/ftm-intelligence.svg";
import FTMLaw from "../images/ftm-law.svg";
import FTMFin from "../images/ftm-finance.svg";
import FTMCoin from "../images/ftm-coin.svg";
import IMPMalware from "../images/malware.svg";
import IMPPolice from "../images/police.svg";
import IMPHack from "../images/hack.svg";
import IMPIso from "../images/iso-data.svg";
import { Link } from "react-router-dom";

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={IDWBannerImage}
      alt=""
      className="bilic-article-template__hero__background"
    />
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      <span>Pattern recognition </span>  engine
    </h5>
    <p>
      Bilic Pattern Recognition Engine employs network analysis algorithms to automatically detect patterns and irregularities in a users transaction data
{" "}
    </p>
    <div className="bilic-article-template__hero__links">
      {/* <a
        className="btn bilic-article-template__hero__links__btn"
        target="_blank"
        href="https://www.docdroid.net/G52pJwT/billic-investor-pitch-pdf#page=16"
        rel="noreferrer"
      >
       Demo
      </a>
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank"
        href="https://osf.io/dbf4n/"
        rel="noreferrer"
      >
        Research
      </a> */}

      <Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>
      
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>

    </div>
  </>
);

const PR = () => (
  <ArticleTemplate
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-pr"
  >
    <section className="container">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__usecase">
              <h5>
                The capabilities of a 
                <span>
                  <span> pattern</span>
                </span>{" "}
                <span>
                  <span>Recognition</span>
                </span>{" "}
                engine
              </h5>

              <p>
                The Pattern Recognition Engine allows the user to automatically detect a series of patterns to speed up investigations and fact finding. Such patterns include Money Laundering Transaction Patterns, whereby tracking money back to another linked wallet, or carousel can be identified. 
              </p>

              <a href="https://ftm.bilic.io/marketplace" className="btn">
                {" "}
                Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPMalware} alt="" />
                  <h6>KYC’d Pattern</h6>
                  <p>Patterns of regular interaction with KYC’d platform to allow investigators to easily de-anonymise wallets
</p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPHack} alt="" />
                  <h6>Mixer Pattern</h6>
                  <p>Graph patterns with links to mixers and tumblers</p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPPolice} alt="" />
                  <h6>Organised Scam Pattern</h6>
                  <p>A graph pattern with where funds are moved from one wallet to an operational wallet with large number of small value transactions.</p>
                  <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Address <span> Linkability </span>  ML <span> Model </span>
                </h3>
                <p style={{ maxWidth: "580px" }}>
                  Tornado Cash is a protocol ensuring that users can break a link in on-chain activity for the purpose of improving transaction privacy between the recipient and destination address.
                </p>
                <p style={{ maxWidth: "580px" }}>
                  Acting as a complex token mixer, Tornado Cash utilizes smart contracts that accept deposits made in ETH that are then withdrawn to other addresses.
                </p>
                <p>
                  This hides the flow of funds and makes it harder to track the funds, turning the protocol a good option to be explored for money laundering purposes.
                </p>

                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
              <img
                className="img-fluid bilic-article-template__content__teaser__illustration"
                src={IMPIso}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4 style={{ maxWidth: "560px" }}>
                  Who needs the intelligence marketplace
                </h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMLaw} alt="" />
                  <h3>Law Enforcement and Investigation Companies</h3>
                  {/* <p>
                    Blockchain forensics
                    takes data from the blockchain to interpret the flow of
                    digital assets
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMFin} alt="" />
                  <h3>Financial Institutions and Compliance Officers</h3>
                  {/* <p>
                    On May 27, 2021, the Dubai Electronic Security Center issued
                    a press release stating that the new cryptocurrency- Dubai
                    Coin
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>Intelligence Customers</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMCoin} alt="" />
                  <h3>Crypto Business</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section hidden
      className="bilic-landing__cta bilic-article-template__demo"
      style={{ padding: "7rem 0" }}
    >
      <div className="container">
        <div className="">
          <div className="row justify-content-center text-center">
            <div className="col">
              <header className="bilic-landing__cta__header">
                <h2>Book a Demo</h2>
                <p>
                  {" "}
                  Talk with one of our experts about Bilic's product and service
                  offerings
                </p>
                <a href="https://calendly.com/saminated/60-minutes" className="btn">
                  <span> Demo </span>{" "}
                </a>
              </header>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ArticleTemplate>
);

export default PR;
