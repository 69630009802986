import React from "react";

function AgentStarOne() {
  return (
    <div style={{position: 'absolute', insetInlineEnd: '270px', insetBlockStart: '260px'}} className="d-none d-lg-flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="38"
        fill="none"
      >
        <g fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
          <path
            fill="#B8FC6A"
            d="M28.528 1.804a.703.703 0 0 0-1.098.368l-.762 2.666a3.512 3.512 0 0 1-2.414 2.414l-2.667.762a.703.703 0 0 0 0 1.351l2.667.762a3.513 3.513 0 0 1 2.414 2.413l.762 2.667a.702.702 0 0 0 1.35 0l.763-2.667a3.511 3.511 0 0 1 2.413-2.413l2.667-.762a.703.703 0 0 0 0-1.351l-2.667-.762s-1.097-.472-1.52-.894a3.512 3.512 0 0 1-.893-1.52l-.762-2.666a.702.702 0 0 0-.253-.368Z"
          />
          <path
            fill="#51DD29"
            d="M14.9 8.973a1.406 1.406 0 0 0-2.197.736l-1.524 5.333a7.027 7.027 0 0 1-4.827 4.827l-5.334 1.524a1.406 1.406 0 0 0 0 2.702l5.334 1.524a7.027 7.027 0 0 1 4.827 4.827l1.524 5.333a1.405 1.405 0 0 0 2.702 0l1.524-5.333a7.025 7.025 0 0 1 4.827-4.827l5.334-1.524a1.405 1.405 0 0 0 0-2.702l-5.334-1.524s-2.194-.943-3.04-1.788a7.026 7.026 0 0 1-1.787-3.039L15.405 9.71a1.405 1.405 0 0 0-.505-.736Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h35.135v37.331H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default AgentStarOne;

;
