import React from "react";

function AgentStarTwo() {
  return (
    <div
      style={{
        position: "absolute",
        insetInlineStart: "-52px",
        insetBlockStart: "-24px",
      }}
      className="d-none d-lg-flex"
    >
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3266_395)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M45.2681 1.13673C45.1439 1.04296 44.9923 0.992221 44.8367 0.992188C44.6811 0.992221 44.5295 1.04296 44.4052 1.13673C44.281 1.2305 44.1907 1.3622 44.148 1.51189L43.371 4.23079C43.204 4.81635 42.8901 5.34959 42.4595 5.7802C42.029 6.2108 41.4956 6.52453 40.9101 6.69176L38.1911 7.46845C38.0415 7.51131 37.9099 7.60169 37.8162 7.72598C37.7225 7.85025 37.6719 8.00163 37.6719 8.15726C37.6719 8.31287 37.7225 8.46427 37.8162 8.58853C37.9099 8.7128 38.0415 8.80319 38.1911 8.84606L40.9101 9.62275C41.4956 9.78998 42.029 10.1037 42.4595 10.5343C42.8901 10.9649 43.204 11.4982 43.371 12.0837L44.148 14.8026C44.1907 14.9522 44.2812 15.0838 44.4054 15.1775C44.5297 15.2711 44.6811 15.3218 44.8367 15.3218C44.9923 15.3218 45.1436 15.2711 45.2679 15.1775C45.3922 15.0838 45.4826 14.9522 45.5254 14.8026L46.3023 12.0837C46.4694 11.4982 46.7833 10.9649 47.2138 10.5343C47.6444 10.1037 48.1777 9.78998 48.7632 9.62275L51.4823 8.84606C51.6318 8.80319 51.7635 8.7128 51.8571 8.58853C51.9509 8.46427 52.0015 8.31287 52.0015 8.15726C52.0015 8.00163 51.9509 7.85025 51.8571 7.72598C51.7635 7.60169 51.6318 7.51131 51.4823 7.46845L48.7632 6.69176C48.7632 6.69176 47.6444 6.2108 47.2138 5.7802C46.7833 5.34959 46.4694 4.81635 46.3023 4.23079L45.5254 1.51189C45.4826 1.3622 45.3924 1.2305 45.2681 1.13673Z"
            fill="#B8FC6A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.4854 15.8779C30.2369 15.6904 29.934 15.5889 29.6226 15.5889C29.3112 15.5889 29.0083 15.6904 28.7597 15.8779C28.5112 16.0655 28.3305 16.3289 28.2449 16.6283L26.6915 22.0661C26.357 23.2372 25.7295 24.3037 24.8683 25.1649C24.0071 26.0261 22.9405 26.6535 21.7694 26.988L16.3314 28.5414C16.0322 28.6271 15.769 28.8079 15.5817 29.0564C15.3943 29.305 15.293 29.6077 15.293 29.9189C15.293 30.2301 15.3943 30.5331 15.5817 30.7816C15.769 31.0301 16.0322 31.2108 16.3314 31.2966L21.7694 32.85C22.9405 33.1845 24.0071 33.8119 24.8683 34.6732C25.7295 35.5343 26.357 36.6008 26.6915 37.772L28.2449 43.2096C28.3306 43.509 28.5114 43.7721 28.76 43.9595C29.0085 44.1467 29.3113 44.2481 29.6226 44.2481C29.9338 44.2481 30.2366 44.1467 30.4852 43.9595C30.7337 43.7721 30.9145 43.509 31.0002 43.2096L32.5537 37.772C32.8881 36.6008 33.5156 35.5343 34.3769 34.6732C35.2381 33.8119 36.3046 33.1845 37.4758 32.85L42.9137 31.2966C43.2131 31.2108 43.4762 31.0301 43.6636 30.7816C43.8508 30.5331 43.9522 30.2301 43.9522 29.9189C43.9522 29.6077 43.8508 29.305 43.6636 29.0564C43.4762 28.8079 43.2131 28.6271 42.9137 28.5414L37.4758 26.988C37.4758 26.988 35.2381 26.0261 34.3769 25.1649C33.5156 24.3037 32.8881 23.2372 32.5537 22.0661L31.0002 16.6283C30.9147 16.3289 30.734 16.0655 30.4854 15.8779Z"
            fill="#51DD29"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.5611 37.7715C8.43684 37.6777 8.28526 37.627 8.12965 37.627C7.97404 37.627 7.82246 37.6777 7.69819 37.7715C7.57393 37.8653 7.4837 37.997 7.44093 38.1467L6.664 40.8656C6.49697 41.4511 6.18306 41.9844 5.7525 42.415C5.32194 42.8456 4.78861 43.1593 4.20311 43.3265L1.48404 44.1032C1.33443 44.1461 1.20285 44.2365 1.10919 44.3607C1.01551 44.485 0.964844 44.6364 0.964844 44.792C0.964844 44.9476 1.01551 45.099 1.10919 45.2233C1.20285 45.3476 1.33443 45.438 1.48404 45.4808L4.20311 46.2575C4.78861 46.4247 5.32194 46.7385 5.7525 47.1691C6.18306 47.5997 6.49697 48.1329 6.664 48.7185L7.44093 51.4374C7.4837 51.587 7.57415 51.7186 7.69842 51.8122C7.82268 51.9059 7.97404 51.9566 8.12965 51.9566C8.28526 51.9566 8.43662 51.9059 8.56088 51.8122C8.68515 51.7186 8.7756 51.587 8.81837 51.4374L9.5953 48.7185C9.76233 48.1329 10.0762 47.5997 10.5068 47.1691C10.9374 46.7385 11.4707 46.4247 12.0562 46.2575L14.7752 45.4808C14.9248 45.438 15.0564 45.3476 15.15 45.2233C15.2439 45.099 15.2945 44.9476 15.2945 44.792C15.2945 44.6364 15.2439 44.485 15.15 44.3607C15.0564 44.2365 14.9248 44.1461 14.7752 44.1032L12.0562 43.3265C12.0562 43.3265 10.9374 42.8456 10.5068 42.415C10.0762 41.9844 9.76233 41.4511 9.5953 40.8656L8.81837 38.1467C8.7756 37.997 8.68537 37.8653 8.5611 37.7715Z"
            fill="#B8FC6A"
          />
        </g>
        <defs>
          <clipPath id="clip0_3266_395">
            <rect width="52" height="52" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default AgentStarTwo;
