import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from "../images/BH-white.svg";
import './footer.scss';

function Footer() {
  return (
    <footer className="bilic-global-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="">
              <div className="bilic-global-footer__brand">
                <div className="bilic-global-footer__brand__logo">
                  {/* <img alt="BHDE Logo" className="logo" src={} /> */}
                  <h3> bilic</h3>
                </div>
                <p>AI-Driven agents for finance security and compliance </p>
                <p className="copyright">
                  Copyright {new Date().getFullYear()} bilic.io <br></br>
                  Contact: info@bilic.io
                  {/* <a href="mailto:email@example.com">Send Email</a> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="bilic-global-footer__links">
              <h2>Socials</h2>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/bilic/?viewAsMember=true"
                    rel="noopener noreferrer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="bhde_io"
                  >
                    <i className="fab fa-linkedin" />
                    linkedin
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://discord.gg/Pc6EJCMSWk"
                    rel="noopener noreferrer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="bhde_io"
                  >
                    <i className="fab fa-discord" />
                    Discord
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://twitter.com/spectralhash"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://medium.com/bilic-io"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-medium" />
                    Medium
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-md-2">
            <div className="bilic-global-footer__links">
              <h2>Media</h2>
              <ul>
                {/* <li>
                    <Link to="/articles/funds">Fund Recovery Services </Link>
                  </li> */}
                <li>
                  <a
                    target="_blank"
                    // href="https://www.docdroid.net/G52pJwT/billic-investor-pitch-pdf"
                    href="https://pitch.com/public/92e37b88-4bf0-4195-9870-db1959363827"
                    rel="noopener noreferrer"
                  >
                    Deck
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://medium.com/bilic-io"
                    rel="noopener noreferrer"
                  >
                    Medium Articles
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="bilic-global-footer__links">
              <h2>Company</h2>
              <ul>
                <li>
                  <p>
                    Company Number: 14232181
                  </p>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/11ghY9D2j0vA1pGIVFKUigShmSIPdy52y47pZIb5dpes/preview"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/1JHF_hdn6RRCHKd8Rx6yVy0MTA-XNg_Cf04OWSojPr9w/preview"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://docs.google.com/document/d/11lfNFnBObzRLzjZLsKEV4I8BBUBksmif0sWSFS5CzBU/preview"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                </li>


              </ul>
            </div>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
