import React, { useState } from "react";
const FORM_ENDPOINT = "https://public.herotofu.com/v1/26dc0e30-9a8f-11ed-82c7-3d7607318e65"; // TODO - fill on the later step
// import './index.scss';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div className="d-flex form-success align-items-center">
          <div className="">
            <i className="fa-regular fa-circle-check"></i>
          </div>
          <div className="">
            <p>Thank you!</p>
            <p>We'll be in touch soon.</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      target="_blank"
    >
      <div className="row">
        <div className="col">
          <div className="mb-5 pt-0">
        <input
          type="text"
          placeholder="Your name"
          name="name"
          className="form-control"
          required
        />
      </div>
     
          </div> 
          <div className="col">
          <div className="mb-5 pt-0">
        <input
          type="email"
          placeholder="Email"
          name="email"
          className="form-control"
          required
        />
      </div>
     
          </div> 
        
      </div>
      
      <div className="mb-5 pt-0">
        <textarea
          placeholder="Your message"
          name="message"
          className="form-control"
          required
        />
      </div>
      <div className="mb-3 pt-0 d-flex justify-content-center">
        <button
          // className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          className="font-bold text-white btn text-sm px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="submit"
        >
          Send Message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;