import React from "react";
import ArticleTemplate from "../../../templates/articletemplate/articletemplate";
import "./index.scss";
import BannerImageIMP from "../images/intelligence-banner.svg";
import IDWBannerImage from "../images/binary.webp";
import FTMSense from "../images/ftm-intelligence.svg";
import FTMLaw from "../images/ftm-law.svg";
import FTMFin from "../images/ftm-finance.svg";
import FTMCoin from "../images/ftm-coin.svg";
import IMPMalware from "../images/malware.svg";
import IMPPolice from "../images/police.svg";
import IMPHack from "../images/hack.svg";
import IMPIso from "../images/iso-data.svg";
import { Link } from "react-router-dom";

const ArticleTemplateHeroBgImgs = () => (
  <>
    <img
      src={IDWBannerImage}
      alt=""
      className="bilic-article-template__hero__background"
    />
     <img
      src={BannerImageIMP}
      alt=""
      className="bilic-article-template__hero__background bilic-article-template__hero__background-imp"
    />
  </>
);

const ArticleTemplateHero = () => (
  <>
    <h5>
      Intelligence <span> Marketplace</span> 
    </h5>
    <p>
      The bilic intelligence marketplace is a state of art one stop platform for users and organization to report and research suspicious incidents involving malware, hacks scams and fraudulent activities.{" "}
    </p>
    <div className="bilic-article-template__hero__links">
      {/* <a
        className="btn bilic-article-template__hero__links__btn"
        target="_blank"
        href="https://www.docdroid.net/G52pJwT/billic-investor-pitch-pdf#page=16"
        rel="noreferrer"
      >
       Demo
      </a>
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank"
        href="https://osf.io/dbf4n/"
        rel="noreferrer"
      >
        Research
      </a> */}

<Link className="btn bilic-article-template__hero__links__btn" to="/contact">Request a Demo</Link>
      
      <a
        className="btn bilic-article-template__hero__links__btn bilic-article-template__hero__links__btn-alt"
        target="_blank" href="https://youtu.be/mO9-S74hS2o?t=83" rel="noreferrer">
        Walk through
      </a>


    </div>
  </>
);

const IMP = () => (
  <ArticleTemplate
    articletemplateherobgimgs={
      <ArticleTemplateHeroBgImgs></ArticleTemplateHeroBgImgs>
    }
    articletemplatehero={<ArticleTemplateHero></ArticleTemplateHero>}
    templatename="bilic-article-template-imp"
  >
    {/* <section className="bilic-article-template__content__tagline">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11">
            <header className="">
               <h4>
                {" "}
                one-stop shop <br className="d-none d-lg-block" /> for <span> treat intelligence </span>
              </h4>
              <p>
                The Bilic Intelligence Market Place serves as a one-stop shop for threat intelligence information, each incident report submitted through the portal is analyzed, traced and verified by our security professionals. After aunthenticity is verified, the information is saved on the intelligence data warehouse (IDW) without jeopardizing the anonymity of its victims
              </p>
            </header>
          </div>
        </div>
      </div>
    </section> */}

    <section className="container">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__usecase">
              <h5>
                <span>
                  <span>Intelligence,</span>
                </span>{" "}
                <span>Reports</span> and a wealth of{" "}
                <span>
                  <span> information</span>
                </span>{" "}
                at our users fingertips
              </h5>

              <p>
                Case reports can be purchased and investigations can be
                requested by organisations. To initiate our intelligence
                marketplace Bilic is providing a list of long gone and active
                cases with the option to subscribe.
              </p>

              <a href="https://ftm.bilic.io/marketplace" className="btn">
                {" "}
                Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bilic-article-template__content__features-wrap">
              <div className="bilic-article-template__content__features__list">
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPMalware} alt="" />
                  <h6>Report Malware</h6>
                  <p>Users and organization can report malware incidents</p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPHack} alt="" />
                  <h6>Report Hacks</h6>
                  <p>Users and organization can report hacks</p>
                </div>
                <div className="bilic-article-template__content__features__list__item">
                  <img src={IMPPolice} alt="" />
                  <h6>Report Fraud</h6>
                  <p>Users and organization can report fradulent activities</p>
                  <a href="https://medium.com/bilic-io">Learn More <i className="fa-solid fa-circle-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="bilic-article-template__content__teaser bilic-article-template__content__teaser-lefttext">
      <div className="container">
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-one"/> */}
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="bilic-article-template__content__teaser-wrap">
              <div className="bilic-article-template__content__teaser__text">
                <h3>
                  Intelligence and our <span> KPIs</span> for{" "}
                  <span> decision making</span>
                </h3>
                <p style={{ maxWidth: "580px" }}>
                  Intelligence MarketPlace serves as a one-stop shop for Threat
                  Intelligence information, allowing users to report security
                  issues such as malware, hacks, scams, and fraud.
                </p>
                <p style={{ maxWidth: "580px" }}>
                  Each incident report submitted through the platform is
                  analysed, traced, and verified by our community of security
                  professionals.
                </p>
                <p>
                  After each report is examined and determined to be authentic,
                  the information is saved on the Intelligence Data Warehouse
                  (IDW) without jeopardizing the anonymity of its victims and
                  reporters
                </p>
                <p style={{ maxWidth: "580px" }}>
                  Additionally Bilic is providing a transparent view on its KPIs
                  for partners and investigators and many feedback options to
                  constantly improve operations and investigations.
                </p>

                <a href="https://ftm.bilic.io/marketplace" className="btn">
                  {" "}
                  Demo
                </a>
              </div>
              <img
                className="img-fluid bilic-article-template__content__teaser__illustration"
                src={IMPIso}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <img src={BannerCurve} alt="" className="bilic-article-template__content__background-curve bilic-article-template__content__background-curve-two"/> */}
      </div>
    </section>

    <section className="bilic-article-template__content__solutions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bilic-article-template__content__solutions-wrap">
              <div className="bilic-article-template__content__solutions__header">
                <h4 style={{ maxWidth: "560px" }}>
                  Who needs the intelligence marketplace
                </h4>
              </div>
              <div className="bilic-article-template__content__solutions__list">
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMLaw} alt="" />
                  <h3>Law Enforcement and Investigation Companies</h3>
                  {/* <p>
                    Blockchain forensics
                    takes data from the blockchain to interpret the flow of
                    digital assets
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMFin} alt="" />
                  <h3>Financial Institutions and Compliance Officers</h3>
                  {/* <p>
                    On May 27, 2021, the Dubai Electronic Security Center issued
                    a press release stating that the new cryptocurrency- Dubai
                    Coin
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMSense} alt="" />
                  <h3>Intelligence Generators</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
                <div className="bilic-article-template__content__solutions__list__item">
                  <img src={FTMCoin} alt="" />
                  <h3>Crypto Business</h3>
                  {/* <p>
                    A significant part of the global economy is immersed in
                    dirty practices which make them illegal proceeds from
                    illicit business transactions.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section hidden
      className="bilic-landing__cta bilic-article-template__demo"
      style={{ padding: "7rem 0" }}
    >
      <div className="container">
        <div className="">
          <div className="row justify-content-center text-center">
            <div className="col">
              <header className="bilic-landing__cta__header">
                <h2>Book a Demo</h2>
                <p>
                  {" "}
                  Talk with one of our experts about Bilic's product and service
                  offerings
                </p>
                <a href="https://calendly.com/saminated/60-minutes" className="btn">
                  <span> Demo </span>{" "}
                </a>
              </header>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ArticleTemplate>
);

export default IMP;
